.gl-bonus{
  &__title{
    padding-right: .5rem;
    font-size: 18px;
    margin-top: 30px;
    border-top: 1px solid;
  }

  &__input-row{
    padding: 10px 0;
  }

  &__input-error{
    color: #DA4D15;
    font-size: 16px;
    padding: 0 5px;
  }

  &__label{
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    width: 50%;
    padding: 0 10px;
  }

  &__number-input{
    line-height: 30px;
    padding: 2px 10px;
    border-bottom: 1px solid #8E959B;
    text-align: right;
    outline: none;

  }
  &__number-input::-webkit-inner-spin-button,
  &__number-input::-webkit-outer-spin-button
  {
    -webkit-appearance: none;
    margin: 0;
  }
}
@media only screen and (min-width: 48em){
  .gl-bonus{
    &__input-width{
      width: 48%;
      max-width: 48%;
      margin: auto;
    }
  }
}
@media only screen and (max-width: 48em){
  .gl-bonus{
    &__label{
      width: 100%;
      padding: 16px 10px;
    }
    &__input-width{
      width: 98%;
      max-width: 98%;
      margin: auto;
    }
  }
}