.modal {

  &__wrapper {
    top:0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 500;
    min-height: 100vh;
    opacity: 0.70;
    background: #000;
  }

  &__container {
    border-radius: 5px;
    z-index: 100000;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    overflow-y: auto;
    @media (min-width: 750px) {
      width: 700px;
      margin: initial;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &--small {
      min-height: 0;
      width: 500px;
    }
    &--extra-small{
      width: 400px;
    }

    &--large {
      width: 900px;
      max-height: 90vh;
    }
  }

  &__title {
    border-radius: 4px 4px 0 0;
    color: #ffffff;
    padding: 10px;
  }

  &__content{
    display: block;
    opacity: 1;
    padding: 30px 20px;
    overflow: hidden;
    background: #ffffff;
    min-height: 500px;
    width: 100%;

    &--lessPadding {
      padding: 25px 15px;
    }
    &--extra-small{
      min-height:300px;
    }
  }
}
@media(max-width: $max-tablet-width) {
  .modal{
    &__container{
      width: 80%;
      margin: initial;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media(max-width: $max-mobile-width) {
 .modal{
    &__container{
      top: 20%;
      left: 5%;
      width: 90%;
      position: fixed;
      z-index: 100000;
      border-radius: 2px;
      overflow-y: auto;
      transform: none;
      &--small {
        width: 90%;
      }
      &--extra-small{
        width: 90%;
      }
      &--large {
        width: 90%;
      }
    }
    &__content{
      min-height: unset
    }
  }
}
