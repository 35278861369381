$user-text-highlight: 18px;

$image-height: 140px;

.g-user {
  &-name {
    @extend %vodafone-bold;
    font-size: $user-text-highlight;
    width: fit-content;
    align-self: center;
    margin-bottom: 10px;
  }

  &__link {
    height: fit-content;
    align-self: center;
    max-width: $image-height;
    min-width: $image-height;
    max-height: $image-height;
    min-height: $image-height;
  }

  &-container {
    margin-top: 65px;
    display: flex;
    height: 105px;
    justify-content: flex-end;
    align-content: center;
    position: relative;
    // z-index: $above-content;       
    text-align: left;
    width: 100%
  }

  &-content {
    margin-top: 30px;
    min-height: fit-content;
    height: min-content;
    position: relative;
    z-index: $above-content;
  
    &--home {
      &:after {
        position: absolute;
        content: '';
        background: rgba(255, 255, 255, 0.20);
        width: 100%;
        @include g-resized-elements;
        top: 75px;
        left: 0;
        z-index: $behind-content;
      }

    }
  }

  &__info {
    display: flex;
    max-height: $image-height;
  }

  &-data {
    margin-left: 10px;
    // margin-top: 10px;
    height: calc(#{$image-height} / 2);
    display: flex;
    flex-direction: column;
  }

  &-avatar {
    align-self: center;
    height: $image-height;
    width: auto;
    border-radius: 50%;
    border-color: white;
    border-width: 10px;
    z-index: $above-content;
    background: white;

    &--plain {
      z-index: 0;
    }

    &--small {
      height: 90px;
      width: 90px;
    }
  }

  &-points {
    &__value {
      @extend %vodafone-bold;
      margin-right: 5px;
    }
  }
}

@media ( max-width: 576px ){
  .g-user {
    &-avatar {
      align-self: center;
      height: $image-height;
      width: 90px;
      height:90px;
      z-index: $above-content;
      border:none;
    }
    &-content {
      margin-top: 0;
      &--home {
        &:after{
          display: none;
        }
      }
    }
    &-data{
      justify-content: flex-end;
    }
  }
 
}
