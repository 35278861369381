.gl-objectives{
  &__card {
    display: flex;
    flex-direction: column;
    border-radius: 1px;
    padding: 1.5rem;
  }

  &__description {
    & > * {
      word-wrap: break-word;
      letter-spacing: 0.6px;
      line-height: 25px;
    }
  }

  &__progress {
    margin: auto 0;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #F1F1F1;

    &--rule {
      height: fit-content;
      margin: .75rem 0;
      padding: 3px 5px;
    }
  }
  &__line {
    height: 1px;
    background-color: #D8D8D8;
  }

  &__back {
    padding: 1px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #BDC3C7;
    color: #ffffff;
  }

  &__modal {
    max-height: 90vh;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  &__modal::-webkit-scrollbar{
    display: none;
  }
}
