.g-rankings{
  @extend %vodafone-bold;

  &__avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-color: white;
    border-width: 2px;
  }

  &__table{
    border-collapse: separate;
    border-spacing: 0px 18px;
  }
  &__title{
    font-size: $title-size;
    margin-bottom: 1rem;

    &--small {
      font-size: $small-title-size;
      }
  }

  &__table-container{
    @extend %dark-grey;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
    padding: 20px;
  }
  &__table-nav{
    @extend %dark-grey;
    font-size: 11px;
    box-shadow: 0px -3px 10px 0 rgba(0, 0, 0, 0.17);
    background: white;
  }

  &__table-header{
    @extend %dark-grey;
    border-bottom: 1px solid rgba(142, 149, 155, 0.4);
  }
  &__data{
    @extend %dead-grey;
    border-top: 11px solid #ffffff;
    &-separator{
      //border-color: $ranking-table-separator;
      border-width: 1px;
      width:100%
    }
  }
  &__selector{
    @extend %dark-grey;
    

    &--active{
      border-bottom-width: 4px;
    }
  }
  &__selector-link{
    width: 100%;
    display: block;
    z-index: $above-content;       
  }
  &__selector-container{
    display: flex;
    flex-direction: column;
    text-decoration: none;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
    background: white;
  }
  &__goal-container{
    color: white;
    box-shadow: 0 2px 20px 0 rgba(72, 145, 3, 0.17);
  }
  &__arrow{
    cursor: pointer;
    position: absolute;
    width: 32px;
    height: 32px;
    z-index: 10;
    top: 20%;
    font-size: 0;
    line-height: 0;
    fill: white;
    &--next{
      right: 2%;
    }
    &--left{
      left: 2%;
      transform: rotate(180deg);
    }
  }
  &__dots{
    padding: 0;
    li{
      font-size: 4.75rem;
      width: auto;
      height: auto;
      margin: 0 0.5px;
    }
  }
  &__slider{
    padding: 0 20px;
    .slick-list{
      padding-top: 10px;
      width: 95%;
      margin: auto;
      pointer-events: none;
      .slick-active{
        cursor: unset;
      }
    }
    .slick-dots{
      padding-top: 10px;
      width: 95%;
      margin: -60px auto auto auto;
      position: static;
    }
  }
}
