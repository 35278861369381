.pagination{
  display:flex;
  padding:0;
  padding-left: .5rem;
  font-family: Quicksand-light;
  font-weight: 900;
  flex-wrap: wrap;
  li:first-child{
    margin-left: 0;
  }
}
.pagination li{
  margin: 1px 6.5px;
  list-style: none;
  line-height: 40px;
  min-width:40px;
  font-size: 20px;
  background-color:white;
  text-align:center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  border-radius: 4px;
  a{
    padding: 8px 11px;
  }
}
.active-block{
  background-color: #002569 !important;
  color:white;

}