
.g-quiz {
  @extend %centered-bg;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 1px;
  min-height: fit-content;
  height: 250px;
  @include gradient {
    border-radius: 1px;
  }

  &__title {
    font-size: 32px;
  }

  &__description {
    margin-right: 200px;
    font-size: 18px;
  }

  &__wrap{
    font-size: 18px;
  }

  &-card {
    padding: 2rem 8rem;
    border-radius: 3px;

    &__question {
      font-size: 19px;
      text-align: center;
    }
    &__loader {
      padding: 0 4px;
      background-color: #F1F1F1;
      border-radius: 4px
    }
    &__answer {
      width: 100%;
      padding: 2rem;
      background-color: #E9E9E9;
      position: relative;
      font-size: large;

      &--correct {
        background: white;
        position: absolute;
        top:20px;
        right: 20px;
        border-radius: 20px;
        padding: 3px;
        font-size: 10px;
        width: 20px;
        height: 20px;;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

}

.info-table {
  &__row {
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 10px;
  }
}

@media (max-width: 500px){
  .g-quiz{
    &__description {
      margin: 10px 0;
    }

    &__wrap{
      display: grid;
    }

    &-card {
      padding: 2rem 8rem;
      border-radius: 3px;

      &__answer {
        width: 100%;
        padding: 1rem;
        background-color: #E9E9E9;
        border-radius: 40px;
        position: relative;
      }
    }
  }

  .info-table {
    &__row {
      font-size: 7px;
    }

    &__button {
      font-size: 7px;
      display: block;
    }
  }

  .q-center {
    justify-content: center;
  }
}

@media( max-width: 700px)
{
  .g-quiz {
    &-card {
      padding: 2rem 2rem;
    }
  }
}

@media(max-width: 800px){
  .info-table {
    &__row {
      font-size: $med-title-size;
    }

    &__button {
      font-size: $med-title-size;
      text-align: center;
    }
  }
}
