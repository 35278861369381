$letter-spacing-fonts : 0.5px;

%roboto, .roboto {
    font-family: 'Roboto', sans-serif;
    letter-spacing: $letter-spacing-fonts ;
}

%roboto-bold, .roboto-bold {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    letter-spacing: $letter-spacing-fonts ;
}

%roboto-light, .roboto-light {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: $letter-spacing-fonts ;
}

%roboto-bolder {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    letter-spacing: $letter-spacing-fonts ;
}

%vodafone, .vodafone {
    font-family: 'Vodafone-Light', sans-serif;
    letter-spacing: $letter-spacing-fonts;
}

%vodafone-bold, .vodafone-bold {
    font-family: 'Vodafone-Bold', sans-serif;
    font-weight: bold;
    letter-spacing: $letter-spacing-fonts;
}

%vodafone-bolder, .vodafone-bolder {
    font-family: 'Vodafone-Regular', sans-serif;
    letter-spacing: $letter-spacing-fonts;
}

.lighter {
    font-weight: 500;
}

.line-height-20 {
    line-height: 20px
}

@font-face{
    font-family: "Vodafone-Regular";
    src: url("../../fonts/VodafoneRg-Regular.ttf")
}

@font-face{
    font-family: "Vodafone-Bold";
    src: url("../../fonts/VodafoneRg-Bold.ttf")
}

@font-face{
    font-family: "Vodafone-Light";
    src: url("../../fonts/VodafoneLt-Regular.ttf")
}
  