.gl-custom-slider {
  @extend %row;
  position: relative;
  margin: 0 -10px;

  &__between{
    @extend %row--center-y;
    @extend %row--space;
  }
  &__arrow {
    width: 30px;
    height: 30px;

    &--left {
      transform: rotate(180deg);
    }
  }

  &__item {
    padding: 0 15px;
  }

  &__list {
    @extend %row;
    @extend %row--center-y;
    @extend %row--space;
    overflow: hidden
  }

  &__button {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    outline: none;
    &:focus {
      transform: scale(1.05);
      outline: none;
    }
    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
