.gl-levels{
  &__user{
    &--container{
      width: 99%;
      float: right;
      display:flex;
      background:white;
      margin-top: -50px;
      justify-content: flex-end;
      border-bottom-left-radius: 30% 100px;
    }
    &--loader{
      width: 60%;
      margin-right: 0.5rem
    }

  }
  &__container, &__container-mini{
    position: relative;
  }
  &__container{
    height: 60px;
  }
  &__container-mini {
    height: 50px;
  }

  &__loader_bar {
    height:15px;
    position: absolute;
    top:30%;
    border-radius: 25px;
    width: 100%;
    background: #DCDDE1;
  }
  &__gro_bar {
    height:15px;
    position: absolute;
    top:30%;
    border-radius: 25px;
    width: 100%;
    background: #DCDDE1;
  }
  &__flags{
    &-container, &-container-mini{
      @extend %row;
      justify-content: space-between;
      position: absolute;
      width: 97%;
      left: 1.5%;
      right: 1.5%;
    }
    &-container{
      top: 0;
    }
    &-container-mini {
      top: 12.5%;
    }
  }
  &__flag{
    &-container{
      position:relative;
     
    }
    &-requirement{
      position: absolute;
      width: 175%;
      text-align: center;
      top: -1.3rem;
      font-size: 0.95rem;
      left: -1rem;
    }

    &-locked{
      position: absolute;
      top: -10px;
      left: 55%;
      height: 30px;
      width: 30px;
    }
    &-content{
      position: absolute;
      width: 70%;
      left: 15%;
      &-middle{
        top: 24%;
      }
      &-topmiddle{
        top: 17%;
      }
      &-top{
        top: 10%;
      }
    }
  }
  &__requirements{
    &__container:nth-child(4n + 1){
      padding-left: 0;
    }
    &__container:nth-child(4n){
      padding-right: 0;
    }
    &__loader{
      &-shadow{
        -webkit-filter: drop-shadow( 0px 0px 17px rgba(0, 0, 0, .5));
        filter: drop-shadow( 0px 0px 17px rgba(0, 0, 0, .5))
      }
      &-circle{
        &-progress{
          stroke: #F1F1F1;
          transition: stroke-dashoffset 0.35s;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
          -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
        }
        &-wrapper{
          position: absolute;
          top: 15%;
          left: 15.75%;
          margin: 7.5px 0;
          &-points{
            color: #d5d5d5;
            font-size: 2.3rem;
            letter-spacing: -1px;
            margin-top: -5px;
          }
        }
      }
    }
    &__progress{
      &-bubble{
        &-container{
          border-radius: 50%;
          bottom:15px;
          left: 72%;
          height:30px;
          width:30px;
          -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.5);
          -moz-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.5);
          box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.5);
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
        }
        &-text{
          font-size: 0.8em;
          letter-spacing: 0.25px;
        }
      }
    }
  }
  &__products{
    &__title{
      &-container{
        @extend %row;
        margin-left: -10px;
        justify-content: space-between;
      }
      &-subcontainer{
        @extend %row;
      }
      &-level{
        &-description{
          letter-spacing: 1.6px;
          font-size: 0.875rem;
          font-weight: 500;
        }
        &-text{
          font-size: 1.5rem;
          margin-top: -2px;
          font-weight: bold;
        }
      }
      &-text{
        font-size: 1.75rem;
        margin-top: 5px;
        margin-left: 10px;
      }
      &-highlevel{
        &-container{
          line-height: 4rem;
        }
        &-text{
          vertical-align: middle;
          font-size: 0.85rem;
          color: #d5d5d5;
          display: inline-block;
          line-height: normal;
        }
      }
    }
  }
}
.gro-flag-content {
  font-size: 0.6rem;
  text-align: center;
}
.gro-text-content {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}


@media(max-width: 1025px){
  .gl-levels{
    &__requirements{
      &__container:nth-child(3n + 1){
        padding-left: 0;
      }
      &__container:nth-child(3n){
        padding-right: 0;
      }
    }
  }
}

@media(max-width: 590px){
  .gl-levels{
    &__requirements{
      &__container:nth-child(2n + 1){
        padding-left: 0;
      }
      &__container:nth-child(2n){
        padding-right: 0;
      }
    }
  }
}
