.gl-sales {
  &__row {
    padding: 20px 0;
    border-bottom: 1px solid transparentize($color: $dead-grey, $amount: .9);
    &:last-of-type {
      border: none;
    }
  }

  &__cell {
    padding-right: auto;
    display: flex;
    align-items: center;
  }

  &__input {
    border-radius: 0;
    border-width: 1px;
    border-color: $light-grey;
    width: 100%;
    transition: border-color 100ms ease-in;
    display: flex;
    align-items: center;
    input {
      margin: 0;
      padding: 8px 12px;
      &:focus{
        outline: none;
      }
    }
    &:hover {
      border-color: $dark-grey;
    }

    &--direct {
      padding: 8px 12px;
    }
  }

  &__button {
    border-width: 1px;
    border-color: $light-grey;
    &:hover {
      border-color: $dark-grey;
    }
  }
}
