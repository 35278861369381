.Spinner {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index:11;

}
.spinner-wrapper{
  width: 100vw;
  height: 100vh;
  z-index:11;
  position:fixed;
  top:0;
}