.g-header {
  background: white;
  @extend %vodafone;

}


@media(max-width: 900px){

  .g-header {
    justify-content: center;
    margin-top: 65px;
  }

}
