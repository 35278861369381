@import 'pallete';

.tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20%;
  width: 100%;
}

.tab-list {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0;
  height: 100%;
  width: 100%;
}

.tab-content {
  width: 100%;
}

.tab-list-item {
  display: inline-block;
  font-size: 1.45rem;
  font-weight: 600;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
    

  &--active {
    border-color: $dark-grey;
    border-bottom-width: 4px;
  }
};

.tab-list-active {
  border-bottom-width: 4px;
}

// column container
 
.dashboard-column-container {
 padding: 10px
}

.dashboard-content-column {
  height: 380px;
}

.dashboard-content-column-with-message {
  height: 330px;
}

.dashboard-content-full-row-column {
}

.dashboard-content-full-with-message {
}

// message

.content-message-container {
  height: 50px;
}

.content-message-text {
  padding: 15px 10px 15px 10px;
}

// text

.dashboard-text-label {
  font-size: 1.7rem;
}

.dashboard-text-value {
  font-size: 2.5rem;
}

// table

.dashboard-table {
  padding: 10px;
}

.dashboard-table-header-row {
  height: 3rem;
  max-height: 3rem;
  border-bottom: solid 1px rgb(0, 0, 0);
}

.dashboard-table-body-row {
  height: 2rem;
  max-height: 3rem;
  border-bottom: solid 1px rgb(0, 0, 0);
} 
