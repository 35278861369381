.user-info{
  border-radius: 10px 10px 0 0;
  margin-top: 60px;
  background-color: #002569;

  .user-profile{
    border-radius: 50%;
    height: 120px;
    width: 120px;
    z-index: 2;
    margin-top: -70px;
  }
  .user-name{
    
    font-size: 22px;
    color:white;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;
    word-break: break-word;
    hyphens: auto;
  }
}
.user-points{
  letter-spacing: 0px;
  background: linear-gradient(50deg, #00CAC4,#002569);
  font-size: 30px;
  color: white;
  text-align:center;
  padding: 15px 0;
  word-break: break-word;
}
.user-content-info{
  .user-tabs{
    padding: 25px 0px 25px 0;
  }
}
.profile-personal-info-container div{
  overflow-wrap: break-word;
  hyphens: auto;
  max-width: 180px;
  min-width: 160px;
}
.no-hyphens {
  hyphens: none;
}

.profile-name-container {
  word-break: break-word;
  hyphens: auto;
  max-width: 140px;
}

.user-history{
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  background:white;
  padding:20px 0;
  
  &-container{
    padding: 10px 15px;
  }
  &-title{
    font-weight:normal;
    color: #002569;
  }
  &-text{
    font-weight:bold;
    color: $gray;
    font-size: 22px;
  }
}
.line-height-80{
  line-height:80px;
}
.user-button-tabs{
  font-size:14px;
  line-height:45px;
  margin: 0 6px;
  text-decoration:none;
  text-align:center;
  border-radius: 0;
  border-radius: 5px;
  padding: 0;  
}
.user-active-button{
  
  background-color: #12339C;
  color: white;
  line-height:67px;
  text-decoration:none;
  text-align:center;
  margin: -8px 10px -10px 10px;
  border-radius: 3px 3px 0 0;
}
.user-button-tabs-text{
  display:inline-block;
  vertical-align: middle;
  line-height: normal;
  padding: 5px;
}
.user-header{
  border-bottom: 1px solid $gray;
  .order-th{
    padding: 20px 0px;
  }
  .order-points{
    padding: 20px 0 20px 30px;
  }
  .order-date{
    padding: 20px 30px 20px 0; 
  }
 
  .ranking-th {
    padding-right: 20px
  }
  
}
.user-row{
  height: 85px;
  line-height: 80px;
  padding: 10px 0;
  border-bottom: 1px solid $gray;

  p{
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    color: $gray;
    
  }
  .text-status{
    background-color: $gray;
    color: white !important;
    padding: 10px;
    width:80%;
  
  }
  .order-checkbox{
    background-image: url('../../img/checkbox.png');
    width:19px;
    height:19px;
    display:block;
    margin:auto;
  }
  .order-checkbox-completed{
    background-image: url('../../img/checkbox-completed.png');
    width:17px;
    height:17px;
    display:block;
    margin:auto;
  }

  .order-link{
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    color: $gray;
    text-decoration: none;
  }
  
}
.order-show-status{
  background-color: $gray;
  height: 30px;
  line-height:30px;
  p{
    color: white;
  }
}

.template-text-newsletter {
  margin-top: 5px;
}

.user-used-points {
  background-color: #eaeaef;
}

.avatar-picker{ 
  bottom: 5px;
  background: rgb(212, 26, 26);
  position: absolute;
  color: white;
  z-index: 3;
  padding: 5px;
  font-size: 20px;
  border-radius: 50%;
  right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 860px) {
  .profile-personal-info-container {
    flex-direction: column;
    div {
      max-width: initial;
      margin-bottom: 20px;
    }
  }
}

.lhn {
  line-height: normal !important;
}