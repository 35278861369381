$gl-title-size: 18px;
$gl-navbar-title-size: 20px;
$gl-med-title-size: 16px;
$gl-small-title-size: 14px;
$gl-big-title: 25px;
$gl-very-small-size: 12px;

.gl-small-title-size {
  font-size: $gl-small-title-size;
}
.gl-text-size {
  font-size: $gl-title-size;
}

.gl-very-small-text{
  font-size: $gl-very-small-size;
}

.gl-big-title-size {
  font-size: $gl-big-title;
}

.gl-med-title-size {
  font-size: $gl-med-title-size;
}

//z-index deepness

$gl-behind-everything: -2;
$gl-behind-content: -1;

$gl-above-content: 1;
$gl-above-content-2: 2;
$gl-above-content-3: 50;
$gl-above-content-4: 250;
$gl-above-content-5: 1000;

$gl-higher-content: 10000;
