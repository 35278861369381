$g-product-height: 320px;
$g-title-margin: 30px;
$g-border-width: 2px;
.g-product{
  $p-margin: 10px;
  &__container {
    @extend %vodafone;
    // border-radius: 3px;
    @extend %column;
    @extend %column--center-x;
    @extend %column--space;

    height: $g-product-height;
    margin: 10px;
    min-height: 0;
    transition: transform 300ms ease-in-out;
    &:hover {
      transform: scale(1.05)
    }

    &--post {
      margin: 0;
      border-width: 2px;
      width: 160px;
      height: 350px;
      padding: 0;
      &:hover {
        transform: initial;
      }
    }
  }

  &__title {
    font-weight: bold;
    text-align: center;
    margin: 15px $g-title-margin;
    font-size: $title-size;
    width: calc(100% - #{$g-title-margin} * 2);
    &--post {
      @extend %vodafone;
      font-weight: 500;
      margin: 5px 0;
      justify-content: center;
      width: 100%;
    }
  }

  &__image {
    height: 170px;
    &--post {
      width:  calc(160px - #{$g-border-width} * 2);
      height: auto;
    }
  }

  &__points-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    &--post {
      height: 75px;
    }
  }

  &__points-value{
    font-weight: bold;
    &--post {
      @extend %vodafone-bold;
      font-size: 32px;
    }
  }

  &__action-container {
    $action-margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 15px $action-margin;
    width: calc(100% - #{$action-margin} * 2);

    &--post {
      margin: 0;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 22.5px; 
      padding-bottom: 5px;
    }

  }
  &__loader{
    &-container{
      padding: 7.5px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      
    }
    &-bar{
      height: 10px;
      width: 100%;
      background-color: #F1F1F1;
    }
    &-description{
      font-size: 14px;
      font-family: 'Vodafone-light';
    }
  }

  &__points-label{
    font-size: $small-title-size;
    &--post {
      @extend %vodafone;
      font-size: 18px;
    }
  }

  &__icon {
    font-size: 18px;
    cursor: pointer;
  }
}


.g-product-detail{
  &__title {
    @extend %vodafone;
    font-size: $title-size;
  }

  &__description {
    @extend %vodafone;
    line-height: 24px;
  }

  &__image {
    min-width: 170px;
    max-width: 170px;
    min-height: 200px;
    max-height: 200px;
  }

  &__points {
    font-family: 'Vodafone-Regular', sans-serif;
    font-weight: bold;
    letter-spacing: 3px;
    font-size: $big-title;;
  }

  &__cart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: fit-content;
  }
  &__content {
    display: flex;
    flex-direction: row;

    &--wide {
      flex: 1;
    }
  }
}

@media(max-width:767px) {

  .g-product{
    &__container {
      margin: 10px 0;
      &--post{
        height: 260px;
      }
    }
    &__image{
      &--post{
        width: calc(80px - 2px * 2);
      }
    }
    &__content {
      flex-direction: column;
      align-items: center;
      margin-bottom: 1em;
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1em;
    }
  }
  .catalog__vertical-line{
    display: none;
  }
  
  .g-product-detail {
    &__description{
      padding-bottom: 2em;
      border-bottom: 1px solid $light-grey;
    }
    &__title {
      text-align: center;
    }
    &__image {
      align-self: center;
    }

  }
}
