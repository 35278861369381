
.g-input{

  &--search {
    width: 13rem;
    padding: 0px 5px;
    outline: 0;
    border-bottom: 1px solid #8E959B;
  }
}


@media(max-width: 550px){
  .g-input{
    &__container{
      width: 100%;
    }
  }
}
