.shopcart-navbar{
  box-shadow: 0 2px 30px 0 rgba(0,0,0,0.17);
}
.cart-item{
  line-height:50px;
  font-size: 13px;
  padding-left: 25px;
  // background: white;
  position:relative;
  color: $gray;
  text-align:center;
  &-text{
    line-height: normal;
    vertical-align: middle;
    display: inline-block;
  }

  &:after {
    content: "";
    position: absolute;
    z-index:3;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    filter: drop-shadow(2px -3px 2px rgba(0,0,0,0.17));
    // border-left: 25px solid white ;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
   
  }
  &:before {
    content: "";
    position: absolute;
    z-index:3;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  
  }
  &:nth-child(1){
    &:before {
      content: none;
      border: none;
    }
  }
  &:nth-child(3){
    &:after {
      content: none;
      border: none;
    }
  }
}

.cart-checkout-container {
  margin-bottom: 40px;
}

.w-95{
  width:95%;
}
.w-65{
  width:57.5%;
}
.w-10{
  width: 12.5%;
}
.w-5{
  width: 5%;
}
.prod-th-title{
  color: $gray;
  line-height:14px;
  font-size: 14px;
  padding-left: 10px;
}
.cart-header{
  border-bottom: 1px solid $gray;
  th{
    padding: 20px 0;
  }
}
.cart-row{
  /*height: 110px;*/
  line-height: 105px;
  padding: 10px 0;
  border-bottom: 1px solid $gray;
  p{
    padding: 0;
    font-size: 20px;
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }
  &-signal{
    padding: 0 10px;
    color: #E7802C;
    cursor:pointer;
  }
  &-blue{
    color: #002569;
  }
  &-gray{
    color: $gray;
  }
  &-trash{
    font-size: 20px;
    display:block;
    margin-bottom: -5px;
    cursor: pointer;
  }
  &-column{
    margin:20px 0;
  }
  &-product-title{
    color: #E7802C;
    
  }
}
.cart-confirmation{
  td{
    padding: 20px 0;
    color: #002569;
    font-size: 38px;
  }
}
.cart-footer{
  border-bottom: 1px solid $gray;
  td{
    padding: 20px 0;
    color: #002569;
    font-size: 38px;
  }
}
.cart-button{
  padding: 10px;
}
.cart-address-border{
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}
.cart-title-block{
  line-height: 30px;
  &-text{
    padding-left: 10px;
    font-size:16px;
    line-height:normal;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline;
  }
}
.cart-warning-address-info{
  border-radius:0 0 4px 4px;
  background: rgb(234, 234, 239);
  padding: 20px 20px
}

.cart-warning-container {
  word-break: break-word;
  hyphens: auto;
  >* {
    padding: 10px 0;
    font-size: 14px;
    color: #706F6F;
    font-style: normal;
    font-weight: normal;
  }
}


@media only screen and (max-width: 400px) {
  .cart-item-text {
    margin-left: -5px;
    font-size: 11px;
  }
}


.title-product, .title-points, .title-quantity, .title-value, .title-null {
  color: #979797;
  line-height: 14px;
  font-size: 12px;
  padding-left: 10px;
  float: left;
}

.title-product {
  width: 50%;
  padding-top: 12px;
}

.title-points {
  width: 15%;
  padding-top: 12px;
}

.title-quantity {
  width: 15%;
  padding-top: 12px;
}

.title-value {
  width: 15%;
}

.title-null {
  width: 5%;
}

.cart-header-height{
  height: 40px;
}

.cart-item-product-image{
  padding:0;
}

.cart-item-product-image, .cart-item-product, .cart-item-image, .cart-item-points, .cart-item-quantity, .cart-item-value, .cart-item-null {
  float: left;
}
.cart-item-product-image{
  width: 50%;
}

.cart-item-product-mobile{
  width: 100%;
  color: rgb(227, 130, 0);
  display:none;
}

.cart-item-product{
  width: 100%;
  color: rgb(227, 130, 0);
}

.cart-item-points{
  width: 15%;
}

.cart-item-quantity{
  width: 15%;
}

.cart-item-value{
  width: 15%;
}

.cart-item-null {
  width: 5%;
}

.cart-row{
  line-height:0;
  min-height: 180px;
  display: flex;
  padding: 10px;
}

.cart-footer {
  padding: 10px 0;
}




@media only screen and (max-width: 625px) {
  .cart-item-product-mobile {
    display: block;
    position: absolute;
    word-wrap: anywhere;
    width: 76%;
  }

  .cart-item-product-image, .cart-item-product, .cart-item-points, .cart-item-quantity, .cart-item-value, .cart-item-null {
    margin-top: 50px;
  }

  .cart-item-product {
    display: none;
  }

  .title-product, .title-points, .title-quantity, .title-value, .title-null,
  .cart-item-product, .cart-item-points, .cart-item-quantity, .cart-item-value, .cart-item-null {
    width: 23.75%;
  }

  .cart-item-null{
    margin-top: 31px;
  }

  .cart-row p{
    font-size: 17px;
  }
}

