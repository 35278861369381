.gl-login {

  &__container-login {
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
    background:white;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__button-cart {
    height: 50px;
    min-width: 180px;
    padding: 0 10px;
    font-size: 18px;
  }

  &__button-primary {
    color:white;
    background: rgb(127, 127, 127);

    &:hover {
      background-color: rgb(51, 51, 51);
    }
  }

  &__input {
    width: 300px;
    font-size: 18px;
    color: rgb(127, 127, 127);
    border-bottom: 2px solid rgb(127, 127, 127);
    padding: 4px;
    font-weight: bold;
  }
  
  &__input:focus {
    outline: none;
  }
  
  &__input::placeholder {
    color: rgb(127, 127, 127);
    font-weight: bold;
    opacity: 1; // firefox
  }

  &__link {
    color: rgb(127, 127, 127);
    cursor: pointer;
    display: block;
    text-align: center;
    font-size: 16px;
    text-decoration: underline;

    &:hover {
      color: rgb(51, 51, 51);
    }
  }

  &__image {
    width: auto;
    height: 100px;
  }
  
}