.gl-profile {
  @extend %vodafone-bold;

  &__title {
    font-size: $gl-title-size;
    padding-right: .5rem;
    margin-bottom: 10px;

    &--small {
      font-size: $gl-small-title-size;
    }
  }

  &__image {
    border-radius: 50%;
    height: 120px;
    margin-bottom: .5rem;
    margin: 0 30px .5rem 30px;

    &-container {
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__checkbox-container {

    &__checkbox-input {
      width: 1.3em;
      height: 1.3em;
      border: 2px solid #009432;
      background-color: #FFF;
      border-radius: 25%;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
    }

    &__checkbox-input:enabled:hover {
      background-color: #009432;
    }

    &__checkbox-input:checked {
      background-color: #009432;
    }

    &__checkbox-input:checked:disabled {
      background-color: #009432;
      border: 0px;
      cursor: not-allowed;
    }

    &__checkbox-input:disabled {
      border: 0px;
      background-color: #EA2027;
      cursor: not-allowed;
    }
  }

  &__address-container {
    flex: 1;
    -ms-flex: 1 1 auto;
  }

  &__data-container {
    flex: 1;
    -ms-flex: 1 1 auto;
    margin-left: 6rem;
  }

  &__history-button-container{
    border: 1px solid black;
  }

  &__history{
    &-button-container{
      border: 1px solid black;
    }
    &-button{
      font-weight: bold;
      font-size: $gl-title-size;
      padding: 10px 45px;
      width: fit-content;

      &:focus {
        outline: 0;
      }
    }
    &-chevron-container{
      border: 1px solid black;
      margin: 0 20px;
      display: flex;
      padding: 12px 10px;
      align-content: center;
      cursor: pointer;
    }
    &-chevron{
      width: 20px;
      height: 14px;
      font-size: 1rem;
      
      &-right{
        transform: rotate(180deg);
        
      }
    }
  }

  &__button {
    @extend %vodafone-bold;
    font-size: $gl-title-size;
    padding: 12px 20px;
    border-radius: 1px;
    width: fit-content;
    position: relative;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: 0;
    }

    &--border {
      border-width: 1pX;
    }

    &--right {
      justify-self: flex-end;
      align-self: flex-end;
    }

    &--cart {
      padding: 12px 15px;
    }

    &--small {
      padding: 4px 10px;
      font-size: $gl-small-title-size;
    }

    &--large {
      padding: 8px 14px;
    }

    &--medium {
      padding: 6px 12px;
    }

    &--secondary {
      box-shadow: 4px 4px 4px #888888;
    }
  }

  &__table-cell {
    padding-top: 30px;
  }

  &__order-status {
    display: inline-block;
    width: auto;
    border-radius: 25px;
    text-align: center;
    background: transparent;
    font-weight: normal;
    text-transform: none;

    &--processing {
      border: 1px solid $dead-grey;
      color: $dead-grey;
    }

    &--sent {
      border: 1px solid $soft-blue;
      color: $soft-blue;
    }

    &--failed {
      border: 1px solid $pink-red;
      color: $pink-red;
    }

    &--delivered {
      border: 1px solid $olive-green;
      color: $olive-green;
    }
  }


  &__info {
    @extend %vodafone-bolder;
    letter-spacing: 1.2px;
    display: block;
    font-size: $gl-small-title-size;
    margin-bottom: .5rem;

    &-container {
      display: block;
    }

    &--editable {
      display: flex;
      justify-content: space-between;
    }

    &-icon {
      padding-right: 20px;
    }

    &--value {
      @extend %vodafone-bolder;
      @extend %dead-grey;
    }

    &--input{
      border-bottom: 1px solid;
      padding-bottom: 4px;
      width: 90%;
    }

    &-row {
      border-bottom: 1px solid #F1F1F1;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;

      &:nth-child(1) {
        .gl-profile__table-cell {
          padding-top: 0;
        }
      }

      &-hover {
        &:hover {
          background-color: $hovertablerow
        }
      }
    }

    &-data {
      border-bottom: 1px solid #F1F1F1;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__table {
    min-width: 500px;
    font-size: $gl-title-size;

  }
}

.remove-padding {
  padding: 0;
}

@media (max-width:900px) {
  .gl-profile {
    &__data-container {
      margin-left: 3rem;
    }

    &__button {
      font-size: $gl-med-title-size;
    }

    &__history{
      &-button{

        font-size: $gl-med-title-size;
        padding: 10px 25px;
        width: fit-content;
    
        &:focus {
          outline: 0;
        }
      }
      &-chevron-container{
        padding: 14px 12px;
      }

      &-chevron{
        width: 16px;
        height: 12px;
      }
    }

    &__title {
      font-size: $gl-med-title-size;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 10px;
    }

    &__table {
      font-size: $gl-small-title-size;
    }
  }
}


@media (max-width:800px) {
  .gl-profile {
    &__data-container {
      margin-left: 0px;
    }

    &__button {
      font-size: $gl-small-title-size;
    }

    &__title {
      font-size: $gl-small-title-size;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 10px;
    }

    &__column {
      flex-direction: column;
    }

    &__info {
      &-title {
        color: black;
      }

      &-data {
        border-bottom: 0;
        flex-direction: column;
      }

      &-resized {
        border-bottom: 1px solid #F1F1F1;
        margin-bottom: 10px;
      }

      &-block {
        display: block;
      }

      &-icon {
        padding-right: 0px;
      }
      
    }

    &__center {
      justify-content: center;
    }

    &__between {
      justify-content: space-between;
    }

    &__table {
      font-size: $gl-very-small-size;
    }
  }
}

@media( max-width: 426px){
  .gl-profile {
    &__history{
      &-button{

        font-size: $gl-med-title-size;
        padding: 8px 12px;
        width: fit-content;

        &:focus {
          outline: 0;
        }
      }
      &-chevron-container{
        padding: 9px 10px;
        margin: 0 5px;
      }

      &-chevron{
        width: 10px;
        height: 16px;
      }
    }
  }
}
