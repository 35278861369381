$gray: #979797;
$dark-gray: #706F6F;
$dead-grey: #8E959B;
$light-grey: #dae1e7;

.gray {
  color: $gray
}
.dark-gray {
  color: $dark-gray;
}
