.gl-popup{
  &__image {
    height: 400px;
    @media(max-width: $max-tablet-width){
      height: 325px;
    }
    @media(max-width: $max-mobile-width){
      height: 200px;
    }
  }

  &__info {
    max-height: 200px;
     /* autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    height: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__close {
    position: absolute;
    z-index: $gl-above-content;   
    right: 10px;
    top: 10px;
    padding: 10px 10px;
    border-radius: 2px;
    opacity: 0.7;
  }

  &__more {
    position: absolute;
    margin-bottom: 5px;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(to bottom,rgba(247,247,247,0) 0,rgba(247,247,247,.8) 35%,rgba(247,247,247,.98) 56%,#ffffff 100%);
  }

  &__button {
    position: absolute;
    z-index: $gl-above-content;       
    bottom: 10px;
    transform: translateX(-50%);
    left: 50%;
    width: 120px;
  }
}
