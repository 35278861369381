.footer{
  padding-left: 10%;
  padding-right: 10%;
  @media only screen and (max-width: 600px) {
    padding-left: 1%;
    padding-right: 1%;
  }

  img{
    max-height: 200px;
  }

  .incentive-information{
    padding: 2px 0px;
  }

  .info-link{
    padding: 2px 0px;
    text-decoration: none;
    display: flex;
  }

  .footer-title{
    font-family: 'Quicksand-Bold';
  }

  .contacts-grey{
    padding: 2px 0px;
    font-family: 'Quicksand-Regular';
  }

  .contacts{
    padding: 2px 0px;
    font-family: 'Quicksand-Bold';
  }

  .footer-footer{
    font-family: 'Quicksand-Regular';
  }
}

.link-reset {
  color: inherit;
  &:visited {
    color: inherit;
  }

}
