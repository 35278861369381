.gl-feed {

  &-header {
    margin: 20px 10px 0 10px;
    font-size: $gl-small-title-size;
    text-align: center;
    margin-bottom: 30px;
  }

  &-font {
    font-size: 13px;
  }

  &-description {
    font-size: 10px;
  }

  &-footer {
    font-size: $gl-very-small-size;
  }

   &-list {

    &__line {
      height: 1px;
      width: 90%;
      margin: 0 5%;
      background: $light-grey;
    }

    &__image-container {
      margin-right: 10px;
      width: 100%;
      height: 60px;
    }

    &__thumb {
      width: 100%;
      height: 100%;
    }
  }
}

.gl-feed {

  &-thumb {
    max-height: 100%;
    max-width: 100%;

    &__container{
      height: 130px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
