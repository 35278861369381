$dead-grey: #8E959B;
$light-grey: #dae1e7;

$dark-grey: #4A4A4A;
$to-view-notification: #EAEAEA;
$soft-blue: #4A90E2;
$pink-red: #DC3449;
$olive-green: #417505;
$error:  #fc8181;	
$ranking-table-separator: #FF6752; 
$hovertablerow: #E9E9E9;

$fafafa: #fafafa;
$eee: #eee;

%dark-grey, .dark-grey {
  color: $dark-grey;
}
.dark-grey-border {
  border-color: $dark-grey;
}

%light-grey, .light-grey {
  color: $light-grey;
}
%eee-bg, .eee-bg {
  background: $eee;
}

.light-grey-bg{
  background: $light-grey;
  fill: $light-grey;
}

%dead-grey, .dead-grey {
  color: $dead-grey;
}
.dead-grey-border {
  border-color: $dead-grey;
}

.dead-grey-fill {
  fill: $dead-grey
}
.dead-grey-stroke {
  stroke: $dead-grey
}

.error {
  color: $error;	
}

.fafafa-bg {
  background: $fafafa;
}
