@charset "UTF-8";
.gray {
  color: #979797; }

.dark-gray {
  color: #706F6F; }

.input {
  height: 30px;
  width: 50%; }

.login-user {
  border: 1px solid #979797; }

.login-password {
  border: 1px solid #979797;
  border-top: none; }

.checkbox-label {
  color: #002569; }

.input-round {
  border-radius: 5px; }

.consent-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  padding-left: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.consent-checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

.consent-checkbox-label {
  position: absolute;
  border: 1px solid #a4b0be;
  top: 1.5px;
  left: 0;
  height: 25px;
  width: 25px; }

.consent-checkbox-input:checked ~ .consent-checkbox-label {
  background-color: #0652DD;
  border: 1px solid transparent; }

.consent-checkbox-input:disabled ~ .consent-checkbox-label {
  cursor: not-allowed; }

.consent-checkbox-input:checked:disabled ~ .consent-checkbox-label {
  background-color: #a4b0be;
  border: 1px solid transparent; }

.consent-checkbox-label:after {
  content: "";
  position: absolute;
  display: none; }

.consent-checkbox-input:checked ~ .consent-checkbox-label:after {
  display: block; }

.consent-checkbox-container .consent-checkbox-label:after {
  left: 7px;
  bottom: 2px;
  width: 10px;
  height: 22px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.consent-text-container {
  line-height: 25px; }

.consent-text {
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
  word-break: break-word; }

.consent-seemore {
  cursor: pointer;
  color: #0984e3;
  font-size: 14px; }

.consent-exit {
  font-size: 25px;
  border-radius: 50%;
  background: white;
  color: #979797;
  position: absolute;
  right: 10px;
  top: 10px; }

.consent-modal-description {
  max-height: 80vh;
  overflow-y: scroll; }

.consent-modal-description::-webkit-scrollbar {
  display: none; }

.button {
  font-size: 18px;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  margin: auto;
  display: block;
  outline: none !important; }

.button-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.button-info:hover {
  color: #fff;
  background-color: #138c9e;
  border-color: #138c9e; }

.button-cart {
  height: 50px;
  border-radius: 25px;
  width: 230px;
  display: block; }

.button-secondary {
  color: #E7802C;
  border: 2px solid #E7802C; }

.button-secondary:hover {
  background: #E7802C;
  color: white; }

.button-primary {
  color: white;
  background: #002569;
  border: 2px solid #002569; }

.button-primary:hover {
  color: #002569;
  background: white; }

.btn-secondary {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white;
  border-radius: 5px;
  color: #979797;
  padding: 0px 5px; }

.user-button {
  font-size: 19px;
  line-height: 45px;
  width: 130px; }

.tab-height {
  height: 50px; }

.utility-info-active-tab {
  border-radius: 5px 5px 0 0; }
  .utility-info-active-tab--gamification {
    box-shadow: 4px -9px 8px 3px rgba(0, 0, 0, 0.09);
    border-bottom-width: 4px; }

.invoice-button {
  width: 100px; }

.margin-tabs {
  margin: 0px -10px; }

.link {
  text-decoration: none;
  color: #5bc0de !important;
  cursor: pointer;
  display: block;
  text-align: center;
  height: 40px;
  width: 250px;
  margin: auto; }

.logo {
  max-height: 150px; }

.navbar-header-links {
  background: #eaeaef;
  min-height: 45px;
  line-height: 45px; }
  .navbar-header-links-text {
    font-size: 12px;
    color: #333;
    padding: 0 7.5px;
    border-right: 1px solid;
    text-decoration: none;
    display: inline-block;
    line-height: normal;
    vertical-align: middle; }
  .navbar-header-links-text:hover {
    text-decoration: underline; }
  .navbar-header-links-text:last-child {
    border-right: none;
    padding-right: 0; }

.user-bar {
  font-family: 'Quicksand-Bold'; }

.content-user-bar {
  margin: 10px;
  min-height: 36px; }

.content-user-bar-plus {
  line-height: 56px;
  font-size: 35px; }

.cart-container {
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0; }
  .cart-container .cart-image {
    position: relative;
    top: 5px;
    height: 40px;
    margin-right: 10px; }
  .cart-container .cart-link {
    text-decoration: none;
    padding: 0 10px; }
  .cart-container .cart-items {
    color: white;
    font-size: 40px; }

.items {
  float: left;
  left: -10px;
  font-size: 12px;
  top: 0px;
  padding: 5px;
  z-index: 1000;
  background-color: #E7802C;
  border-radius: 50%;
  color: #FFFFFF;
  font-weight: bold; }

.profile-image {
  height: 65px;
  width: 65px;
  border: 3px solid #00CAC4;
  display: flex;
  border-radius: 50%;
  margin-top: -4px;
  margin-bottom: -5px;
  border-width: 3px; }

.col-blue {
  background-color: #002569;
  color: white;
  text-align: center; }

.col-orange {
  background-color: #E7802C;
  color: white; }

#navbar {
  font-family: Quicksand-light;
  font-weight: 900;
  font-size: 18px; }
  #navbar .nav-item {
    color: #FFF; }
  #navbar .navWide {
    display: flex;
    justify-content: space-between; }
    #navbar .navWide .wideDiv {
      display: flex; }
      #navbar .navWide .wideDiv .selected {
        text-decoration: none;
        display: inline-block;
        padding: 19px;
        background: linear-gradient(to top, #33D9CC 0%, #33D9CC 10%, #04369B 10%, #04369B 100%);
        color: white; }
      #navbar .navWide .wideDiv .selected:hover {
        background: linear-gradient(to top, #2bbbaf 0%, #2bbbaf 10%, #042d80 10%, #042d80 100%);
        color: white; }
  #navbar .navNarrow i {
    padding: 15px 10px;
    float: left;
    cursor: pointer;
    color: #FFF; }
  #navbar .navNarrow .narrowLinks {
    display: none; }
    #navbar .navNarrow .narrowLinks .nav-item {
      text-decoration: none;
      display: block;
      float: left;
      clear: left;
      padding: 14px 100% 14px 2%; }
    #navbar .navNarrow .narrowLinks .selected {
      text-decoration: none;
      display: inline-block;
      padding: 14px 90% 14px 2%;
      background: linear-gradient(to top, #33D9CC 0%, #33D9CC 10%, #04369B 10%, #04369B 100%); }
    #navbar .navNarrow .narrowLinks .selected:hover {
      color: white !important; }

.nav-item {
  height: 100%;
  text-decoration: none;
  padding: 19px; }

.dropbtn {
  height: 100%;
  font-size: 16.6px;
  padding: 19px;
  text-align: left; }
  .dropbtn:focus {
    outline: 0; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 5;
  overflow: auto;
  word-break: break-word;
  hyphens: auto;
  font-size: 16px;
  min-width: 160px; }

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 0; }
  .dropdown .dropdownIcon {
    margin-left: 5px;
    position: relative; }
  .dropdown:hover .dropdown-content {
    display: block; }

.dropdown-content:hover {
  display: block; }

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  margin: auto;
  width: 100%;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17); }

.burger {
  display: none;
  position: fixed;
  top: 5px;
  left: 5px;
  width: fit-content;
  z-index: 50;
  padding: 5px;
  border-radius: 2px; }
  .burger span {
    display: flex;
    height: 3px;
    width: 40px;
    margin: 3px 0px;
    background: white; }

.nav-mobile-close {
  display: none;
  flex-direction: column;
  position: fixed;
  right: 18px;
  top: 15px;
  z-index: 200; }

.show {
  display: block; }

@media (max-width: 800px) {
  .dropbtn {
    width: 100%; }
  #navbar .navWide, #navbar .navWide .wideDiv {
    display: flow-root !important; }
  nav {
    opacity: 1;
    transition: all .4s; }
    nav .cont {
      margin: 0; }
    nav .navWide {
      background: inherit;
      position: fixed;
      min-height: 100%;
      z-index: 100;
      overflow-y: auto;
      top: 0;
      left: 0;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start; }
      nav .navWide .wideDiv {
        flex-direction: column;
        justify-content: flex-start;
        min-height: fit-content;
        display: flow-root; }
      nav .navWide .nav-item {
        display: flex;
        width: 100%;
        height: initial; }
      nav .navWide .dropdown {
        width: 100%;
        transition: 0.3s ease; }
        nav .navWide .dropdown .dropbtn {
          transition: 0.3s ease;
          height: 100%;
          font-size: 16.6px;
          padding: 14px;
          text-align: left;
          height: auto;
          transition: all .4s; }
          nav .navWide .dropdown .dropbtn:focus {
            transition: all .4s;
            outline: 0; }
        nav .navWide .dropdown .dropdown-content {
          transition: 0.3s ease;
          display: none;
          /*display: flex;*/
          flex-direction: column;
          z-index: 0;
          box-shadow: none;
          position: relative; }
          nav .navWide .dropdown .dropdown-content a {
            box-shadow: none; }
        nav .navWide .dropdown:hover .dropdown-content {
          transition: 0.3s ease;
          display: block; }
        nav .navWide .dropdown:hover .fromDropdown, nav .navWide .dropdown:hover .fKmOcp {
          padding-left: 30px; }
  .nav-mobile-close {
    display: flex; }
  .burger {
    display: flex;
    flex-direction: column; }
  .fade {
    opacity: 0;
    display: none; }
    .fade .nav-mobile-close {
      display: initial; } }

@media (min-width: 1045px) {
  nav .navNarrow {
    display: none; } }

@media (max-width: 420px) {
  .margin-logout {
    margin-bottom: 60px !important; } }

.footer {
  padding-left: 10%;
  padding-right: 10%; }
  @media only screen and (max-width: 600px) {
    .footer {
      padding-left: 1%;
      padding-right: 1%; } }
  .footer img {
    max-height: 200px; }
  .footer .incentive-information {
    padding: 2px 0px; }
  .footer .info-link {
    padding: 2px 0px;
    text-decoration: none;
    display: flex; }
  .footer .footer-title {
    font-family: 'Quicksand-Bold'; }
  .footer .contacts-grey {
    padding: 2px 0px;
    font-family: 'Quicksand-Regular'; }
  .footer .contacts {
    padding: 2px 0px;
    font-family: 'Quicksand-Bold'; }
  .footer .footer-footer {
    font-family: 'Quicksand-Regular'; }

.link-reset {
  color: inherit; }
  .link-reset:visited {
    color: inherit; }

.font-quicksand {
  font-family: 'Quicksand-Bold'; }

.font-quicksand-regular {
  font-family: 'Quicksand-Regular'; }

.font-quicksand-light {
  font-family: 'Quicksand-Light';
  font-weight: 900; }

.image-background {
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(90deg, #E38200 10%, 30%, #00369B 60%);
  background-size: cover;
  background-repeat: no-repeat; }
  .image-background--no-overlay {
    position: relative; }

.logo-container img {
  width: 50%;
  height: 200px; }

.w46 {
  width: 46%; }

.w-10 {
  width: 10%; }

.w-15 {
  width: 15%; }

.w-5 {
  width: 5%; }

.default-container {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white; }

.default-container-login {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: auto; }

.default-container-privacy-policy {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white;
  border-radius: 15px;
  margin: 50px;
  padding: 50px; }
  .default-container-privacy-policy > h1 {
    padding: 10px 0; }
  .default-container-privacy-policy > h2 {
    padding-bottom: 10px; }
  .default-container-privacy-policy > p, .default-container-privacy-policy ul {
    margin: 12px 0; }

.template {
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white;
  padding: 20px; }
  .template-container {
    border-radius: 4px;
    background: #eaeaef;
    padding: 20px 15px;
    margin: 25px 0; }
    .template-container--light {
      background: #f5f5f5; }
  .template-show {
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.17); }
  .template-title {
    font-weight: normal;
    color: #002569; }
  .template-block {
    padding: 20px 0; }
  .template-text {
    font-weight: 300;
    color: #eaeaef;
    font-size: 22px; }
  .template-blue {
    min-height: 50px;
    font-size: 24px;
    color: #002569;
    border-bottom: 1px solid #002569;
    padding: 0 10px; }
  .template-errors {
    color: #DA4D15;
    font-size: 16px;
    padding: 10px 10px; }
  .template-hint {
    color: #40739e;
    font-size: 14px;
    padding: 0 5px; }
  .template-pass {
    line-height: 50px;
    font-size: 24px;
    color: #002569;
    border-bottom: 1px solid #002569;
    padding-left: 10px;
    padding-right: 10px; }
  .template-link-blue {
    line-height: 40px;
    font-size: 18px;
    color: #002569;
    text-decoration: underline; }
  .template-warning {
    line-height: 18px;
    letter-spacing: 0.2px;
    background-color: #DA4D15;
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    color: white;
    padding: 5px; }
  .template-info {
    letter-spacing: 2.5px;
    font-size: 19px;
    color: white;
    padding: 12px 0 12px 10px; }
    .template-info--reduced-spacing {
      letter-spacing: 1.5px; }
  .template-passwordinfo {
    background: #eaeaef;
    margin-top: 10px;
    min-height: 50px;
    border-radius: 0 0 4px 4px;
    padding: 0 20px 30px 20px; }
  .template-password-title {
    padding: 10px 0 20px 0;
    color: #706F6F;
    font-weight: bold;
    font-size: 14px; }
  .template-password-requirements {
    font-size: 12px;
    padding-left: 15px;
    color: #706F6F; }
  .template-text-blue {
    color: #002569;
    font-size: 20px; }
  .template-text-newsletter {
    color: #002569;
    font-size: 16px; }
  .template-address {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
    border-radius: 6px; }
    .template-address-title {
      color: #002569;
      font-size: 20px;
      line-height: 80px; }
      .template-address-title-text {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        word-wrap: anywhere; }
    .template-address-blue {
      color: #002569;
      font-size: 20px;
      padding: 2px 0; }
    .template-address-gray {
      color: #979797;
      font-size: 18px;
      padding: 2px 0; }
    .template-address-trash {
      color: #979797;
      font-size: 18px;
      line-height: 80px;
      cursor: pointer; }

.hr-down-left {
  background-color: #E7802C;
  width: 45%;
  height: 3px;
  float: left; }

.ranking-vertical {
  width: 7px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17); }

.background-blue {
  background: linear-gradient(90deg, #00369B 10%, 30%, #00369B 60%); }

.ranking-position {
  position: absolute;
  bottom: 15px;
  left: -20px;
  border-radius: 50%;
  border: 5px solid #1cbbb4;
  height: 45px;
  width: 45px;
  color: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: linear-gradient(115deg, #00369b 0%, 30%, #1cbbb4 70%);
  padding: 0px 5px;
  font-size: 30px; }
  .ranking-position-span {
    font-family: sans-serif; }

.ranking-pointer {
  height: 20px;
  width: 7px; }

.ranking-scale {
  margin-top: -30px; }

.slide-container {
  position: relative;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: #eaeaef; }

.sliding {
  position: relative;
  min-height: 600px;
  max-height: 600px;
  width: 100%;
  z-index: 3; }
  @media (max-width: 1440px) {
    .sliding {
      min-height: 500px;
      max-height: 500px;
      background-position: -58px 0px !important; } }
  @media (max-width: 800px) {
    .sliding {
      min-height: 425px;
      max-height: 425px; } }
  @media (max-width: 500px) {
    .sliding {
      min-height: 350px;
      max-height: 350px; } }
  .sliding img {
    width: inherit;
    height: inherit; }
  .sliding .banner-content {
    position: absolute;
    top: 20%;
    right: 0;
    text-align: left;
    color: white;
    padding: 20px; }
    .sliding .banner-content:before {
      background: #003e51;
      border-radius: 25px;
      opacity: 0.4;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1; }
    .sliding .banner-content .banner-title {
      z-index: 2;
      font-size: 47px;
      font-family: 'Quicksand-Bold';
      font-weight: 800; }
      .sliding .banner-content .banner-title.sub-title {
        font-size: 38px; }
  .sliding .button-wrapper {
    position: absolute;
    bottom: 10%;
    right: 0; }
    .sliding .button-wrapper .banner-button {
      padding: 20px;
      border-radius: 25px;
      font-size: 30px; }

.slick-dots li button::before {
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  top: 1.25px;
  left: 0.25px;
  width: 18px;
  height: 18px;
  content: '•';
  text-align: center;
  opacity: .25; }

.slick-dots li.slick-active button::before {
  opacity: 1;
  color: inherit; }

.slick-active {
  z-index: 1; }

.slick-dots li button:hover::before, .slick-dots li button:focus::before {
  opacity: 1;
  color: inherit; }

.width-available {
  width: 100%; }

.soft-round {
  border-radius: 4px; }

.anchor-reset {
  color: inherit; }
  .anchor-reset:visited {
    color: inherit; }

.feed-thumb {
  height: 100%;
  width: 100%; }

.feed-container {
  min-height: 640px;
  max-height: 640px;
  overflow: auto; }

.layout-above {
  position: absolute;
  top: 0;
  left: 0;
  height: fit-content;
  min-height: 100%;
  width: 100%; }

@media (max-width: 400px) {
  .xs\::w-full {
    width: 100%; }
  .xs\:no-display {
    display: none; }
  .xs\:mb-10 {
    margin-bottom: 2.5rem; }
  .xs\:flex-turn {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }
    .xs\:flex-turn--j-center {
      justify-content: center; }
    .xs\:flex-turn--a-center {
      align-items: center; }
    .xs\:flex-turn--reverse {
      flex-direction: column-reverse; } }

@media (max-width: 800px) {
  .sm\:w-full {
    width: 100%; }
  .sm\:no-display {
    display: none; }
  .sm\:mb-4 {
    margin-bottom: 1rem; }
  .sm\:flex-turn {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start; }
    .sm\:flex-turn--j-center {
      justify-content: center; }
    .sm\:flex-turn--a-center {
      align-items: center; }
    .sm\:flex-turn--reverse {
      flex-direction: column-reverse; }
  .template {
    padding: 10px; } }

@media (max-width: 500px) {
  .sliding .banner-content {
    top: 10%; }
    .sliding .banner-content:before {
      border-radius: 12.5px;
      opacity: 0.4;
      content: '';
      z-index: -1; }
    .sliding .banner-content h1 {
      font-size: 20px; }
    .sliding .banner-content h2 {
      font-size: 18px; }
  .sliding .button-wrapper {
    position: absolute;
    bottom: 12.5%;
    right: 0; }
    .sliding .button-wrapper .banner-button {
      padding: 12.5px;
      border-radius: 17.5px;
      font-size: 16px; } }

.hyphens {
  hyphens: auto; }

.break {
  word-break: break-word; }

.mx-bs {
  margin-right: .5rem;
  margin-left: .5rem; }

.-mx-bs {
  margin-right: -.5rem;
  margin-left: -.5rem; }

.mr-bs {
  margin-right: .5rem; }

.ml-bs {
  margin-left: .5rem; }

.gray {
  color: #979797; }

.text-break {
  word-wrap: anywhere; }

.custom-select__placeholder {
  font-size: 16px; }

.custom-select__indicator-separator {
  background-color: transparent !important; }

.custom-select__menu {
  margin-top: 0px !important;
  border-radius: 0 !important;
  z-index: 10 !important;
  width: calc(100% - 1rem) !important;
  bottom: 0;
  position: absolute;
  height: fit-content; }

.custom-select__control {
  position: relative; }

.custom-select__control--menu-is-open {
  border-radius: 0 !important; }

.custom-select__option {
  text-align: left; }

.custom-select__option:hover, .custom-select__option:active {
  color: white;
  background-color: none;
  background-color: #5bc0de; }

.custom-select__option--is-focused {
  background-color: #5bc0de; }

.custom-select__list {
  position: absolute;
  width: 100%;
  background: #ffffff; }

.custom-select__menu-list {
  background: white; }

.pagination {
  display: flex;
  padding: 0;
  padding-left: .5rem;
  font-family: Quicksand-light;
  font-weight: 900;
  flex-wrap: wrap; }
  .pagination li:first-child {
    margin-left: 0; }

.pagination li {
  margin: 1px 6.5px;
  list-style: none;
  line-height: 40px;
  min-width: 40px;
  font-size: 20px;
  background-color: white;
  text-align: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  border-radius: 4px; }
  .pagination li a {
    padding: 8px 11px; }

.active-block {
  background-color: #002569 !important;
  color: white; }

.Spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11; }

.spinner-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 11;
  position: fixed;
  top: 0; }

.user-info {
  border-radius: 10px 10px 0 0;
  margin-top: 60px;
  background-color: #002569; }
  .user-info .user-profile {
    border-radius: 50%;
    height: 120px;
    width: 120px;
    z-index: 2;
    margin-top: -70px; }
  .user-info .user-name {
    font-size: 22px;
    color: white;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 40px;
    word-break: break-word;
    hyphens: auto; }

.user-points {
  letter-spacing: 0px;
  background: linear-gradient(50deg, #00CAC4, #002569);
  font-size: 30px;
  color: white;
  text-align: center;
  padding: 15px 0;
  word-break: break-word; }

.user-content-info .user-tabs {
  padding: 25px 0px 25px 0; }

.profile-personal-info-container div {
  overflow-wrap: break-word;
  hyphens: auto;
  max-width: 180px;
  min-width: 160px; }

.no-hyphens {
  hyphens: none; }

.profile-name-container {
  word-break: break-word;
  hyphens: auto;
  max-width: 140px; }

.user-history {
  border-radius: 0 0 10px 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white;
  padding: 20px 0; }
  .user-history-container {
    padding: 10px 15px; }
  .user-history-title {
    font-weight: normal;
    color: #002569; }
  .user-history-text {
    font-weight: bold;
    color: #979797;
    font-size: 22px; }

.line-height-80 {
  line-height: 80px; }

.user-button-tabs {
  font-size: 14px;
  line-height: 45px;
  margin: 0 6px;
  text-decoration: none;
  text-align: center;
  border-radius: 0;
  border-radius: 5px;
  padding: 0; }

.user-active-button {
  background-color: #12339C;
  color: white;
  line-height: 67px;
  text-decoration: none;
  text-align: center;
  margin: -8px 10px -10px 10px;
  border-radius: 3px 3px 0 0; }

.user-button-tabs-text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding: 5px; }

.user-header {
  border-bottom: 1px solid #979797; }
  .user-header .order-th {
    padding: 20px 0px; }
  .user-header .order-points {
    padding: 20px 0 20px 30px; }
  .user-header .order-date {
    padding: 20px 30px 20px 0; }
  .user-header .ranking-th {
    padding-right: 20px; }

.user-row {
  height: 85px;
  line-height: 80px;
  padding: 10px 0;
  border-bottom: 1px solid #979797; }
  .user-row p {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    color: #979797; }
  .user-row .text-status {
    background-color: #979797;
    color: white !important;
    padding: 10px;
    width: 80%; }
  .user-row .order-checkbox {
    background-image: url("../../img/checkbox.png");
    width: 19px;
    height: 19px;
    display: block;
    margin: auto; }
  .user-row .order-checkbox-completed {
    background-image: url("../../img/checkbox-completed.png");
    width: 17px;
    height: 17px;
    display: block;
    margin: auto; }
  .user-row .order-link {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    color: #979797;
    text-decoration: none; }

.order-show-status {
  background-color: #979797;
  height: 30px;
  line-height: 30px; }
  .order-show-status p {
    color: white; }

.template-text-newsletter {
  margin-top: 5px; }

.user-used-points {
  background-color: #eaeaef; }

.avatar-picker {
  bottom: 5px;
  background: #d41a1a;
  position: absolute;
  color: white;
  z-index: 3;
  padding: 5px;
  font-size: 20px;
  border-radius: 50%;
  right: 5px;
  cursor: pointer; }

@media only screen and (max-width: 860px) {
  .profile-personal-info-container {
    flex-direction: column; }
    .profile-personal-info-container div {
      max-width: initial;
      margin-bottom: 20px; } }

.lhn {
  line-height: normal !important; }

.shopcart-navbar {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.17); }

.cart-item {
  line-height: 50px;
  font-size: 13px;
  padding-left: 25px;
  position: relative;
  color: #979797;
  text-align: center; }
  .cart-item-text {
    line-height: normal;
    vertical-align: middle;
    display: inline-block; }
  .cart-item:after {
    content: "";
    position: absolute;
    z-index: 3;
    right: -25px;
    bottom: 0;
    width: 0;
    height: 0;
    filter: drop-shadow(2px -3px 2px rgba(0, 0, 0, 0.17));
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent; }
  .cart-item:before {
    content: "";
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent; }
  .cart-item:nth-child(1):before {
    content: none;
    border: none; }
  .cart-item:nth-child(3):after {
    content: none;
    border: none; }

.cart-checkout-container {
  margin-bottom: 40px; }

.w-95 {
  width: 95%; }

.w-65 {
  width: 57.5%; }

.w-10 {
  width: 12.5%; }

.w-5 {
  width: 5%; }

.prod-th-title {
  color: #979797;
  line-height: 14px;
  font-size: 14px;
  padding-left: 10px; }

.cart-header {
  border-bottom: 1px solid #979797; }
  .cart-header th {
    padding: 20px 0; }

.cart-row {
  /*height: 110px;*/
  line-height: 105px;
  padding: 10px 0;
  border-bottom: 1px solid #979797; }
  .cart-row p {
    padding: 0;
    font-size: 20px;
    display: inline-block;
    line-height: normal;
    vertical-align: middle; }
  .cart-row-signal {
    padding: 0 10px;
    color: #E7802C;
    cursor: pointer; }
  .cart-row-blue {
    color: #002569; }
  .cart-row-gray {
    color: #979797; }
  .cart-row-trash {
    font-size: 20px;
    display: block;
    margin-bottom: -5px;
    cursor: pointer; }
  .cart-row-column {
    margin: 20px 0; }
  .cart-row-product-title {
    color: #E7802C; }

.cart-confirmation td {
  padding: 20px 0;
  color: #002569;
  font-size: 38px; }

.cart-footer {
  border-bottom: 1px solid #979797; }
  .cart-footer td {
    padding: 20px 0;
    color: #002569;
    font-size: 38px; }

.cart-button {
  padding: 10px; }

.cart-address-border {
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797; }

.cart-title-block {
  line-height: 30px; }
  .cart-title-block-text {
    padding-left: 10px;
    font-size: 16px;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline; }

.cart-warning-address-info {
  border-radius: 0 0 4px 4px;
  background: #eaeaef;
  padding: 20px 20px; }

.cart-warning-container {
  word-break: break-word;
  hyphens: auto; }
  .cart-warning-container > * {
    padding: 10px 0;
    font-size: 14px;
    color: #706F6F;
    font-style: normal;
    font-weight: normal; }

@media only screen and (max-width: 400px) {
  .cart-item-text {
    margin-left: -5px;
    font-size: 11px; } }

.title-product, .title-points, .title-quantity, .title-value, .title-null {
  color: #979797;
  line-height: 14px;
  font-size: 12px;
  padding-left: 10px;
  float: left; }

.title-product {
  width: 50%;
  padding-top: 12px; }

.title-points {
  width: 15%;
  padding-top: 12px; }

.title-quantity {
  width: 15%;
  padding-top: 12px; }

.title-value {
  width: 15%; }

.title-null {
  width: 5%; }

.cart-header-height {
  height: 40px; }

.cart-item-product-image {
  padding: 0; }

.cart-item-product-image, .cart-item-product, .cart-item-image, .cart-item-points, .cart-item-quantity, .cart-item-value, .cart-item-null {
  float: left; }

.cart-item-product-image {
  width: 50%; }

.cart-item-product-mobile {
  width: 100%;
  color: #e38200;
  display: none; }

.cart-item-product {
  width: 100%;
  color: #e38200; }

.cart-item-points {
  width: 15%; }

.cart-item-quantity {
  width: 15%; }

.cart-item-value {
  width: 15%; }

.cart-item-null {
  width: 5%; }

.cart-row {
  line-height: 0;
  min-height: 180px;
  display: flex;
  padding: 10px; }

.cart-footer {
  padding: 10px 0; }

@media only screen and (max-width: 625px) {
  .cart-item-product-mobile {
    display: block;
    position: absolute;
    word-wrap: anywhere;
    width: 76%; }
  .cart-item-product-image, .cart-item-product, .cart-item-points, .cart-item-quantity, .cart-item-value, .cart-item-null {
    margin-top: 50px; }
  .cart-item-product {
    display: none; }
  .title-product, .title-points, .title-quantity, .title-value, .title-null,
  .cart-item-product, .cart-item-points, .cart-item-quantity, .cart-item-value, .cart-item-null {
    width: 23.75%; }
  .cart-item-null {
    margin-top: 31px; }
  .cart-row p {
    font-size: 17px; } }

.content-images {
  min-height: 30vh; }

.content-container {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white; }

.content-images-full {
  width: 100%;
  height: 45vh; }

.news-date {
  color: #979797;
  font-size: 18px;
  min-width: 80px;
  text-align: right; }
  .news-date-full {
    color: #979797;
    font-size: 22px; }

.news-remove {
  position: absolute;
  right: -18px;
  top: -15px;
  font-size: 30px;
  border-radius: 50%;
  background: white;
  padding: 5px 10px;
  z-index: 2;
  color: #979797; }

.content-text {
  color: #979797;
  font-size: 1rem; }
  .content-text strong {
    font-size: 1.25rem; }
  .content-text > p {
    word-wrap: break-word;
    hyphens: auto;
    width: inherit; }

.news-clicker {
  cursor: pointer; }

.fit-content {
  height: fit-content; }

.news-title {
  font-size: 34px; }

.image-container {
  width: 100%;
  height: 200px;
  border: solid; }

.textbutton {
  color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5); }

.page-content, .page-text-container {
  width: 100%; }

.page-text-container {
  word-break: break-word;
  hyphens: auto; }

.navbar-content-info {
  width: 100%; }

.supporting-documents-button-tabs {
  font-size: 20px;
  min-height: 37px;
  margin: 5px;
  text-decoration: none;
  text-align: center;
  border-radius: 0;
  padding: 10px 5px; }

.supporting-documents-active-button {
  color: white;
  font-size: 20px;
  line-height: normal;
  text-decoration: none;
  text-align: center;
  min-height: 50px;
  border-radius: 3px 3px 0 0;
  padding: 10px 5px; }

.documents-content {
  padding: 40px 20px;
  position: relative; }
  .documents-content * {
    margin: auto; }

.hidden {
  display: none; }

.container {
  padding: 60px; }

.border-tb {
  border-top: solid 1px;
  border-bottom: solid 1px;
  margin: -1px 0; }

.show-more-anchor {
  cursor: pointer;
  padding-bottom: 2px;
  border-bottom: solid 0.5px; }

.close-icon {
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  font-size: 30px;
  border-radius: 50%;
  background: white;
  padding: 5px 10px;
  z-index: 2;
  color: #979797;
  margin-top: 10px;
  cursor: pointer; }

.document-content {
  position: relative;
  hyphens: auto; }

.close-icon-container {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  border-radius: 50%; }

.download-icon {
  font-size: 26px;
  border-bottom: solid 4px; }

.cont .row {
  margin: 0%; }

.content-layout-text {
  margin: 0 6% !important; }

.download-document-button {
  padding: 10px 30px;
  border-radius: 25px;
  margin-top: 30px; }

.product-box {
  border: none;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.17); }

.react-datepicker__input-container input {
  padding: 0.5rem .75rem;
  border-radius: 5px;
  margin-right: 1.5rem;
  color: inherit; }

.invoice__table-header {
  height: 50px;
  font-size: 14px; }

.invoice__table-cell--small {
  width: 8%; }

.invoice__table-cell--medium {
  width: 10%; }

.invoice__table-cell--big {
  width: 12%; }

.invoice__table-row {
  height: 50px; }

.table-text {
  font-size: 13px; }

.invoice-error {
  color: #DA4D15; }

@media (max-width: 450px) {
  .font-10 {
    font-size: 11px; } }

.modal__content {
  min-height: 690px !important; }

.modal__wrapper {
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: 500;
  min-height: 100vh;
  opacity: 0.70;
  background: #000; }

.modal__container {
  border-radius: 5px;
  z-index: 100000;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  overflow-y: auto; }
  @media (min-width: 750px) {
    .modal__container {
      width: 700px;
      margin: initial;
      top: 50%;
      transform: translate(-50%, -50%); } }
  .modal__container--small {
    min-height: 0;
    width: 500px; }
  .modal__container--extra-small {
    width: 400px; }
  .modal__container--large {
    width: 900px;
    max-height: 90vh; }

.modal__title {
  border-radius: 4px 4px 0 0;
  color: #ffffff;
  padding: 10px; }

.modal__content {
  display: block;
  opacity: 1;
  padding: 30px 20px;
  overflow: hidden;
  background: #ffffff;
  min-height: 500px;
  width: 100%; }
  .modal__content--lessPadding {
    padding: 25px 15px; }
  .modal__content--extra-small {
    min-height: 300px; }

@media (max-width: 800px) {
  .modal__container {
    width: 80%;
    margin: initial;
    top: 50%;
    transform: translate(-50%, -50%); } }

@media (max-width: 500px) {
  .modal__container {
    top: 20%;
    left: 5%;
    width: 90%;
    position: fixed;
    z-index: 100000;
    border-radius: 2px;
    overflow-y: auto;
    transform: none; }
    .modal__container--small {
      width: 90%; }
    .modal__container--extra-small {
      width: 90%; }
    .modal__container--large {
      width: 90%; }
  .modal__content {
    min-height: unset; } }

.notification__dropdown__scrolling {
  overflow-y: scroll; }

.notification__dropdown__scrolling::-webkit-scrollbar {
  display: none; }

.d-notification__container {
  min-width: 55px;
  overflow: hidden;
  position: relative; }

.d-notification__bell {
  min-width: 12px; }

.d-notification__dropdown {
  position: absolute;
  border-radius: 5px;
  width: 200px;
  margin-left: 1.2rem;
  margin-top: 4px;
  max-height: 300px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  font-size: 12px;
  background: white;
  z-index: 10; }
  .d-notification__dropdown:before {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
    clear: both;
    position: relative;
    left: 52.5%;
    transform: translateX(-50%);
    top: calc(-8px - 10px);
    width: 0;
    height: 0; }

.d-notification__count {
  position: absolute;
  transform: translateX(-50%);
  z-index: 4;
  margin-left: -13px;
  margin-top: 25px;
  padding: 0.2rem;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center; }

.d-notification__item {
  border-bottom: 1px solid #dae1e7;
  display: flex;
  flex-direction: column;
  padding-top: .5rem;
  padding-bottom: .5rem; }
  .d-notification__item:last-child() {
    border-bottom: none;
    padding-bottom: 0; }
  .d-notification__item:first-child() {
    padding-top: 0; }
  .d-notification__item--to-view {
    background: #dae1e7; }

.login__button {
  width: 290px; }

.login__link {
  color: "#008000";
  align-self: center; }

.custom-inner-html p,
.custom-inner-html h1,
.custom-inner-html h2,
.custom-inner-html h3,
.custom-inner-html h4,
.custom-inner-html h5,
.custom-inner-html h6 {
  margin-left: 0;
  margin-right: 0;
  word-break: break-word; }

.custom-inner-html p {
  margin-top: 1em;
  margin-bottom: 1em; }

.custom-inner-html h1 {
  margin-top: 0.67em;
  margin-bottom: 0.67em; }

.custom-inner-html h2 {
  margin-top: 0.83em;
  margin-bottom: 0.83em; }

.custom-inner-html h3 {
  margin-top: 1em;
  margin-bottom: 1em; }

.custom-inner-html h4 {
  margin-top: 1.33em;
  margin-bottom: 1.33em; }

.custom-inner-html h5 {
  margin-top: 1.67em;
  margin-bottom: 1.67em; }

.custom-inner-html h6 {
  margin-top: 2.33em;
  margin-bottom: 2.33em; }

.custom-inner-html iframe {
  width: 100%; }

@media (max-width: 600px) {
  .custom-inner-html * {
    font-size: .9rem !important;
    word-wrap: break-word; } }

.primary {
  color: #14469B; }

@font-face {
  font-family: "DINMediumAlternate";
  src: url("../../fonts/DINMediumAlternate.ttf") format("truetype"); }

@font-face {
  font-family: "JosefinSans-Bold";
  src: url("../../fonts/JosefinSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "JosefinSans-Light";
  src: url("../../fonts/JosefinSans-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Montserrat-Thin";
  src: url("../../fonts/Montserrat-Thin.otf") format("truetype"); }

@font-face {
  font-family: "MyriadPro-Regular";
  src: url("../../fonts/MyriadPro-Regular.otf") format("truetype"); }

@font-face {
  font-family: "Quicksand-Bold";
  src: url("../../fonts/Quicksand-Bold.otf") format("truetype"); }

@font-face {
  font-family: "Quicksand-Light";
  src: url("../../fonts/Quicksand-Light.otf") format("truetype"); }

@font-face {
  font-family: "Quicksand-Regular";
  src: url("../../fonts/Quicksand-Regular.otf") format("truetype"); }

html, body {
  overflow-x: hidden; }

a {
  text-decoration: none; }

.cont {
  margin: 0 10%; }
  @media only screen and (max-width: 500px) {
    .cont {
      margin: 0 10px; } }
  @media (max-width: 1150px) {
    .cont {
      margin: 0 5%; } }
  .cont--reduced {
    margin-right: 3%;
    margin-left: 3%; }

.padding-cont {
  padding: 0 10%; }
  @media only screen and (max-width: 600px) {
    .padding-cont {
      padding: 0 1%; } }
