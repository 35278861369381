$g-navbar-height: 65px;
$g-content-height: 687px;
$g-header-height: 147px;
$g-banner-regular: 25vh;
%g-navbar {
  &__icon {
    background-size: cover;
    width: 24px;
    height: 24px;
    display: inline-block;
  }
}

.g-navbar {
  @extend %vodafone;
  @extend %p-container;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // min-height: $g-navbar-height;
  position: fixed;
  background-color: white;
  font-size: $navbar-title-size;
  font-weight: bold;
  height: 65px;
  width: 100%;
  top:0;
  left: 0;

  &__link {
    $nav-padding: 15px;
    padding: $nav-padding;
    height: 65px;
    align-items: center;
  }

  &__links  {
    height: 65px;
    display: flex;
    align-items: flex-end;
  }

  &__dropdown-wrapper {
    position: relative;
    display: inline-block;

    &__campaign-icon {
      margin-left: 5px;
      position: relative;
    }
    
    &__campaigns {
      $g-nav-padding: 15px;
      padding: $g-nav-padding;
      height: $g-navbar-height;
      display: flex;
      align-items: center;
    }

    &__campaigns-content {
      display: none;
      position: absolute;
      background-color: #f1f1f1;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 5;
      overflow: auto;
      word-break: break-word;
      hyphens: auto;
      font-size: 16px;
      min-width: 160px;
    }

    &__campaigns-content:hover {
      display: block;
    }

    &__campaigns-content a {
      color: black;
      text-decoration: none;
      display: block;
      margin: auto;
      width: 100%;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
    }

    &:hover {
      .g-navbar__dropdown-wrapper__campaigns-content {
        display: block
      }
    }
  }

  &__logo {
    max-height: 45px;
  }

  &__link-container {
    margin-right: auto;
    margin-left: auto;
  }

  &--scrolling {
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.12);
  }

  &__icon-find {
    background-image: url('../../img/VDF_BrandCenter_icon-find.png');
    @extend %g-navbar__icon;
  }

  &__icons24 {
    width: 24px;
    height: 24px;
  }

  &__icons20 {
    width: 20px;
    height: 20px;
  }

  &__icons16 {
    width: 16px;
    height: 16px;
  }

  &__dropdown {
    $triangle-size: 8px;
    position: absolute;
    border-radius: 5px;
    width: 150px;
    right: 0;
    margin-top: #{$triangle-size};
    padding:10px 5px;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    font-size: $small-title-size;
    background: white;
    &-item {
      padding: 10px;
      display: flex;
    }

    &:before {
      content: '';
      position: absolute;
      right: .75rem;
      top: -#{$triangle-size};
      width: 0;
      height: 0;
      border-left: #{$triangle-size} solid transparent;
      border-right: #{$triangle-size} solid transparent;
      border-bottom: #{$triangle-size} solid #ffffff;
      clear: both;
    }
  }
}

#g-navbar {
  z-index: $above-content-4;
  height: 65px;
}

.simulate-header {
  margin-top: $g-header-height;
}

.simulate-footer {
  margin-bottom: $g-header-height;
}

.g-burger {
  display: none;
  padding: 5px 8px;
  border-radius: 2px;
  span {
    display: flex;
    height: 1px;
    width: 35px;
    margin: 4px 0px;
  }
}
.mobile-show {
  display: none;
}

@media(max-width: 900px){

  .g-burger {
    display: flex;
    flex-direction: column;
  }


  .mobile-hide {
    display: none;
  }
  .mobile-show {
    display: block;
  }

  .nav {
    display: none;
    z-index: $above-content;
    height: 200px;
    &--active {
      position: absolute;
      top:0;
      left:0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: $above-content;  
      overflow-y: scroll; 
      width: 100%;
      margin-top: 60px;
    }
    &--active::-webkit-scrollbar{
      display: none
    }
  }

  .g-navbar {
    align-items: center;
    justify-content: space-between;
    &:before {
      background-size: 120% 100%;
    }

  }

  .background {
    background-color: white;
    position: fixed;
    z-index: $above-content;
    width: 100%;
  }

  .g-icons {
    margin-left: auto;
  }

  .g-navbar__links {
    & > * {
      padding: 20px;
      margin-bottom: 1rem;
      width: 100%;
    }
  }

  #g-navbar {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

}
