.button{
  font-size: 18px;
  width: 50%;
  height: 40px;
  border-radius: 5px;
  margin: auto;
  display: block;
  outline: none !important;
}

.button-info{
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.button-info:hover{
  color: #fff;
  background-color: #138c9e;
  border-color: #138c9e;
}
.button-cart{
  height:50px;
  border-radius: 25px;
  width:230px;
  display: block;

}
.button-secondary{
  color: #E7802C;
  border: 2px solid #E7802C;
}
.button-secondary:hover{
  background: #E7802C;
  color: white;
}
.button-primary{
  color:white;
  background: #002569;
  border: 2px solid #002569;
}
.button-primary:hover{
  color: #002569;
  background: white;
}
.btn-secondary{
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: white;
  border-radius: 5px;
  color: $gray;
  padding: 0px 5px;
}
.user-button{
  font-size:19px;
  line-height: 45px;
  width: 130px;
}

.tab-height {
  height: 50px;
}
.utility-info-active-tab{
  border-radius: 5px 5px 0 0;

  &--gamification {
    box-shadow: 4px -9px 8px 3px rgba(0,0,0,0.09);
    border-bottom-width: 4px;
  }
}

.invoice-button {
  width: 100px;
}

.margin-tabs{
  margin: 0px -10px;
}