@keyframes anim {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: fit-content;
    opacity: 1;
  }
}

.catalog {
  &__container {
    @extend %p-container;
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
    min-height: $g-product-height;
  }

  &__vertical-line {
    border-left: 1px solid #{$light-grey};
    padding-left: 15px;
  }

  &__horizontal-line {
    height: 1px;
    background: $light-grey;
    &--no-margin {
      margin:0;
    }
  }

  &__line {
    background: $dead-grey;
    display: flex;
    min-height: auto;
    width: 2px;
    margin: 0 10px;
  }

  &__search-elements {
    display: none;
    max-height: 0;
    height: auto;;
    transition-property: max-height;
    transition-duration: 300ms;
    &--extended {
      display: block;
      max-height: 600px;
      transition-duration: 300ms;

    }
    &--no-display {
      display: none;
    }
  }
}


@media(max-width: 800px){
  .catalog{

    &__filter{
      margin: 5px 0;
    }

    &__checkbox{
      flex-direction: column;
    }
  }
}
