%gl-home-shadow {
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.2);
}

$gl-home-elements-margin: 30px;
$gl-home-new-width: 300px;

.content-images-full {
  width: 100%;
  height: 425px;
}

.gl-home {
  
  &__container {
    @extend %row;
  }
  
  &__header{
    &-info{
      box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.2);
      padding: 30px 20px;
      width:100%;
    }
  }

  &__posts {
    margin-right: #{$gl-home-elements-margin};
    max-width: calc(100% - #{$gl-home-elements-margin} - #{$gl-home-new-width});
  }

  &__catalog,
  &__promotion,
  &__accelerator,
  &__wishes {
    @extend %column--wide;
  }

  &__new-item,
  &__catalog,
  &__promotion,
  &__accelerator,
  &__wishes {
    @extend %gl-home-shadow;
    width: 100%;
    margin-bottom: #{$gl-home-elements-margin};
  }
  

  &__catalog,
  &__wishes {
    padding: 30px 20px;
  }


  &__new {
    &-item {
      min-height: 0;
      width: 300px;
      padding: 0;
    }

    &-image {
      width: 300px;
      min-height: 200px;
      max-height: 200px;
    }

    &-type {
      margin-bottom: 10px;
    }

    &-title {
      font-size: $gl-home-elements-margin;
      letter-spacing: 0px;
      transition: color 100ms ease-in-out;
    }

    &-subtitle {
      font-size: 18px;
      color: rgb(51, 51, 51);
      font-weight: 300;
      line-height: 28px;
    }

    &-description {
      &>* {
        line-height: #{$gl-home-elements-margin};
      }

      margin-bottom: 10px;
    }

    &-container {
      @extend %column;
      flex: 1;

      &:nth-last-child() {
        margin-bottom: 0px;
      }
    }

    &-chevron {
      width: 30px;
      height: 30px;
    }

    &-content {
      @extend %column;
      @extend %column--wide;
      padding: 30px;
      ;
    }
  }


  &__promotion {
    // @extend %row--override;
    @extend %row;
    @extend %row--wide;
    min-height:300px;
  }

  &__accelerator {
    @extend %row;
    @extend %row--wide;
    min-height:300px;
  }

  &__catalog {
    &--diff {
      height: calc(80% - #{$gl-home-elements-margin})
    }
  }
}

.gl-post {
  &__label {
    @extend %vodafone-bold;
    margin-bottom: 10px;
  }

  &__title {
    @extend %vodafone;
    font-weight: 500;
    margin-bottom: $gl-home-elements-margin;
    font-size: 32px;

    &--promotion {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}

.gl-wishes {

  &__title {
    font-size: 30px;
  }
}

.gl-promotion {
  @extend %column;
  padding: 30px 20px;
  width: 100%;

  &__image {
    min-width: 300px;
    max-width: 300px;

    &--announcement {
      max-width: 600px;
      min-width: 600px;
    }
  }

  &__title {
    @extend %row;
    @extend %row--center-y;
    margin-bottom: 30px;
  }

  &__chevron {
    width: 20px;
    height: 20px;
  }

  &__description {
    @extend %row;
    @extend %row--space;

    font-size: 18px;
    line-height: 28px;

    &--announcement {
      @include flex;
    }
  }
}

.gl-accelerator {
  @extend %column;
  padding: 30px 20px;
  width: 100%;

  &__image {
    min-height: 200px;
    min-width: 47%;
  }

  &__title {
    @extend %row;
    @extend %row--center-y;
  }

  &__description {
    @extend %row;
    @extend %row--space;

    font-size: 18px;
    line-height: 28px;

  }

}
@media(max-width: 550px) {
  .gl-post {
    &__title {
      font-size: 24px;
    }
  }

  .gl-promotion {
    padding: 30px 20px;

    &__image {
      min-height: 200px;
      height: 200px;
      background-size: cover;
      background-position: center center;
      max-width: 100% !important;
      min-width: 100% !important;
      &--announcement {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100%;
      }
    }

    .gl-content-images-full {
      width: 100%;
      height: 25vh;
    }
  }
  .content-images-full {
    width: 100%;
    min-height: 200px !important;
    max-height: 200px !important;
  }

  .gl-home {
    &__promotion {
      flex-direction: column;
    }
  }
}

@media(max-width: 1025px) {
  .gl-home {
    &__posts {
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }

    &__new {
      width: none;

      &-item {
        display: none;
      }
    }
  }
}

@media(max-width: 1440px) {
  .gl-promotion {
    padding: 30px 20px;

    &__image {
      &--announcement {
        width: 100%;
        min-width: 450px;
        max-width: 450px;
      }
    }
  }
  .content-images-full {
    width: 100%;
    height: 400px;
  }
}

@media(max-width: 800px) {
  .gl-promotion {
    padding: 30px 20px;

    &__image {
      &--announcement {
        width: 100%;
        max-width: 300px;
        min-width: 300px;
      }
    }

  }
  .gl-content-images{
    width: 100%;
    height: 37vh; 
  }
  .content-images-full {
    width: 100%;
    min-height: 350px;
    max-height: 350px;
  }
}