$large-desktop-width: 1920px;
$desktop-width: 1150px;
$tablet-width: 900px;
$tablet-min-width: 550px;
$super-small-width: 320px;
$gl-banner-height: 500px;

$max-laptop-width: 1440px;
$max-tablet-width: 800px;
$max-mobile-width: 500px;


@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin from-tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin from-desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin below-desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$super-small-width}) {
    @content;
  }
}

@mixin resized-elements{
  min-height: $gl-banner-height;
  max-height: $gl-banner-height;
  @media (max-width: #{$max-laptop-width}) {
    min-height: 435px;
    max-height: 435px;
  }
  @media (max-width: #{$max-tablet-width}) {
    min-height: 375px;
    max-height: 375px;
  }
  @media (max-width: #{$max-mobile-width}) {
    min-height: 200px;
    max-height: 200px;
  }
}


@mixin ie-flex-1 {
  display: flex;
  flex: 1;
  -ms-flex: 1 1 auto;
}

@mixin gradient {
  &--soft, &--medium, &--hard{
    &:after, &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      @content
    }
  }

  &--soft {
    &:after, &::after  {
      background-color: rgba($color: #000000, $alpha: 0.35) ;
    }
  }
  &--medium {
    &:after, &::after  {
      background-color: rgba($color: #000000, $alpha: 0.65) ;
    }
  }
  &--hard {
    &:after, &::after  {
      background-color: rgba($color: #000000, $alpha: 0.85) ;
    }
  }

}

%row {
  @extend %flexbox;
  
  &--center-y {
    @include align-items(center);    
  }
  &--center-x {
    @include flex-just(center);    
  }
}

%column {
  @extend %flexbox;
  @include flex-dir(column);
  &--center-y {
    @include flex-just(center);    
  }
  &--center-x {
    @include align-items(center);
    
  }
}

%row, %column {
  &--center {
    @include flex-just(center);
    @include align-items(center);
  }
  &--wide {
    @include flex($fg: 1, $fs: 1, $fb: 0);
    width: 100%;
  }

  &--space {
    @include flex-just($value: space-between);
  }

  &--flex-end {
    @include flex-just($value: flex-end);
  }
  
}

%row--override {
  @include flex-dir(row);  
}
