.g-levels{
  &__user{
    &--container{
      width: 82.25%;
      float: right;
      display:flex;
      background:white;
      margin-top: -50px;
      justify-content: flex-end;
    }
    &--loader{
      width: 65%;
      margin-right: 0.5rem
    }

  }
  &__container, &__container-mini{
    position: relative;
  }
  &__container{
    height: 60px;
  }
  &__container-mini {
    height: 50px;
  }

  &__loader_bar {
    height:15px;
    position: absolute;
    top:30%;
    border-radius: 25px;
    width: 100%;
    background-color: #F1F1F1;
  }
  &__flags{
    &-container, &-container-mini{
      @extend %row;
      justify-content: space-between;
      position: absolute;
      width: 97%;
      left: 1.5%;
      right: 1.5%;
    }
    &-container{
      top: 0;
    }
    &-container-mini {
      top: 12.5%;
    }
  }
  &__flag{
    &-container{
      position:relative;
     
    }
    &-locked{
      position: absolute;
      top: -10px;
      left: 55%;
      height: 30px;
      width: 30px;
    }
    &-content{
      position: absolute;
      width: 70%;
      left: 15%;
      &-middle{
        top: 24%;
      }
      &-top{
        top: 10%;
      }
    }
  }
}