
.g-progress-ring{
  &__loader {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition-delay: 300ms;

  }

  &__container {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: #ffffff;
  }
}

.g-progress-semi-ring {
  &__loader {
    transition: stroke-dashoffset 0.35s;
  }
  &__container {
    transition: stroke-dashoffset 0.35s;
  }
}

.g-rect-loader {
  height: 20px;
  width: 100%;

  &__container {
    height: 30px;
  }

  &--thin {
    height: 10px;
  }
}
