.navbar-content-info {
  width: 100%;
}

.supporting-documents-button-tabs {
    font-size:20px;
    min-height: 37px;
    margin: 5px;
    text-decoration: none;
    text-align: center;
    border-radius: 0;
    padding: 10px 5px;
}

.supporting-documents-active-button {
    color: white;
    font-size: 20px;
    line-height: normal;
    text-decoration: none;
    text-align: center;
    min-height: 50px;
    border-radius: 3px 3px 0 0;
    padding: 10px 5px;
    
}

.documents-content {
    padding: 40px 20px;
    position: relative;
    * {
        margin: auto;
    }
}

.hidden{
    display: none;
}

.container {
    padding: 60px;
}

.border-tb {
    border-top: solid 1px;
    border-bottom: solid 1px;
    margin: -1px 0;
}

.show-more-anchor {
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: solid 0.5px;
}

.close-icon {
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
    font-size: 30px;
    border-radius: 50%;
    background: white;
    padding: 5px 10px;
    z-index: 2;
    color: $gray;
    margin-top: 10px;
    cursor: pointer;
}

.document-content {
    position: relative;
    hyphens: auto;
}


.close-icon-container{
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    border-radius: 50%;    
}

.download-icon {
    font-size: 26px;
    border-bottom: solid 4px;
}


.cont .row {
    margin: 0%;
}

.content-layout-text  {
    margin: 0 6% !important;
}

.download-document-button {
    padding: 10px 30px;
    border-radius: 25px;
    margin-top: 30px;
}