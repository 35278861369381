.gl-cust-page{

  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.2);

  &__image{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__title{
    font-size: 32px;
    padding-top: 0.5rem;
  }

  &__subtitle{
    font-size: 18px;
  }

  &__button{
    margin-top: 0.6rem;
    margin-right: 0.6rem;
  }

}