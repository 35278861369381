.swift-slider-dots{
  margin-top: -40px !important;
  position: relative !important;
  z-index: 10 !important;
  font-size: 40px !important;
}

.swift-slider-dot{
  padding: 7px;
  margin: 0px 5px !important;
  outline: none;
  border: 2px solid white !important;
  border-radius: 20px !important;
}



.slick-slider{
  width: 100%;
  

  .slick-prev{
    background: url(../img/arrow-left.jpg);
    margin-left: 30px;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding: 200px 0px;
    z-index: 10;
    background-color: transparent;
    background-position-y: 50%;
  }

  .slick-next{
    background: url(../img/arrow-right.jpg);
    margin-right: 30px;
    background-size: 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding: 200px 0px;
    z-index: 10;
    background-color: transparent;
    background-position-y: 50%;
  }

  .slick-prev::before{
    content: unset;
  }

  .slick-next::before{
    content: unset;
  }

}

.order-button{
  color: #E38200;
  border: 1px solid #E38200;
  padding: 5px 10px;
  font-size: 9px;
  font-family: 'JosefinSans-Light';
  font-weight: 900;
  border-radius: 20px;
  transition: 100ms transform ease-in, 200ms background ease-out;
  &:hover{
    color:white;
    background-color: #E38200;
    transform: scale(1.05)
  }
}

.product-title{
  font-family: 'Quicksand-Bold';
  color: #E38200;
  font-size: 14px;
}

.product-points{
  font-family: 'Quicksand-Bold';
  color: #1844A2;
  font-size: 28px;
}

.product-points-label{
  font-family: 'Quicksand-Light';
  font-weight: 900;
  color: #1844A2;
  font-size: 15px;
}

.fav{
  color:#D94E1A;
}

.new{
  -webkit-clip-path: polygon(0 0, 0 100%, 60px 0);
  clip-path: polygon(0 0, 0 100%, 60px 0);
  padding-top: 10px;
  background-color: rgb(130, 214, 67);
  position: relative;
  height: 60px;
  text-align: left;
  margin-left: 5px;
  font-size: 20px;
  color: white;
  font-weight: 500;
}

.new-label{
  position: absolute;
  font-size: 15px;
  font-family: 'Quicksand-BOLD';
  padding-left: 0px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
}

.product-carousel-title{
  font-family: 'Quicksand-Bold';
  font-weight: 900;
}

.carousel-inside {
  margin-bottom: 20px;
  background-color: white;
}

.highlights {
  background-image: url("../img/icon-ultima-entradas.jpg")
}

.most-viewed {
  background-image: url("../img/icon-mais-vistos.jpg")
}

.most-viewed,
.highlights {
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
}