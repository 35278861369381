$title-size: 18px;
$navbar-title-size: 20px;
$med-title-size: 16px;
$small-title-size: 14px;
$big-title: 25px;
$very-small-size: 12px;

.small-title-size {
  font-size: $small-title-size;
}
.text-size {
  font-size: $title-size;
}

.very-small-text{
  font-size: $very-small-size;
}

.big-title-size {
  font-size: $big-title;
}

.med-title-size {
  font-size: $med-title-size;
}

//z-index deepness

$behind-everything: -2;
$behind-content: -1;

$above-content: 1;
$above-content-2: 2;
$above-content-3: 50;
$above-content-4: 250;
$above-content-5: 1000;

$higher-content: 10000;
