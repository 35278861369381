.title_payment_data {
  padding: 10px;
  color: #8e959b;
  font-weight: 700;
  border-bottom: 1px solid;
  font-family: 'Vodafone-Light', sans-serif;
  &.defaultLevel {
    width: 75%;
    margin: 0 auto;
    color: #041e42;
    font-family: 'Quicksand-Bold';
  }
}
.table_payment_data {
  width: 100%;
  &.defaultLevel {
    width: 75%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
}
.table_payment_data_th {
  padding: 10px;
  font-size: 20px;
  text-align: left;
  font-weight: 400;
  font-family: 'Vodafone-Light', sans-serif;
  &.defaultLevel {
    color: #041e42;
    text-align: center;
    font-family: 'Quicksand-Bold';
  }
}
.gl-cart {
  @extend %vodafone-bold;

  &__title {
    font-size: $gl-title-size;
  }
  &__cell{
    padding-top: 2em;
    padding-bottom: 2em;
  }

  &__table{
    border-spacing: 5px;
  }

  &__header{
    @extend %dark-grey;
    font-size: 11px;
  }

  &__data{
    color: $dead-grey;
    border-bottom: 1px solid rgba($dead-grey, 0.1);
  }

  &__navbar{
    font-weight: bold;
    justify-content:center;
    color: $dead-grey;
    border-bottom: 1px solid rgba($dead-grey, 0.1);
    &-active{
      @extend %dark-grey;
      &:visited{
        @extend %dark-grey;
      }
    }
  }

  &__number-cell-4 {
    width: 4%;
  }

  &__number-cell-60 {
    width: 60%;
  }

  &__number-cell-10 {
    width: 10%;
  }

  &__address {
    font-size: 16px;
    @extend %dark-grey;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;
    &--small {
      font-size: $gl-small-title-size;
    }

    &--min-height {
      min-height: 280px;
    }
    &--selected {
      border-radius: 3px;
      background: #FAFAFA;
    }
  }

  &__select-address-container{
    display: flex;
    justify-content: space-between;
  }
  &__empty-state {
    height: 32px;
    width: 32px;
  }
}
