
.content-images{
  min-height: 30vh;
}
.content-container{
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  background:white;
}
.content-images-full{
  width: 100%;
  height: 45vh;
}
.news-date{
  color: $gray;
  font-size: 18px;
  min-width: 80px;
  text-align: right;
  &-full{
    color: $gray;
    font-size: 22px;
  }
}
.news-remove{
  position: absolute;
  right: -18px;
  top: -15px;
  font-size: 30px;
  border-radius: 50%;
  background: white;
  padding: 5px 10px;
  z-index: 2;
  color: $gray;
}
.content-text{
  color: $gray;
	font-size: 1rem;
  strong{
    font-size: 1.25rem;
  }

  & > p {
    word-wrap: break-word;
    hyphens: auto; 
    width: inherit;
  }
  
}
.news-clicker{
  cursor: pointer;
}

.fit-content {
  height: fit-content;
}

.news-title {
  font-size: 34px;
}