$triangle: 8px;
.notification__dropdown__scrolling{
  overflow-y: scroll;
}
.notification__dropdown__scrolling::-webkit-scrollbar{
  display:none;
}
.d-notification{
  &__container{
    min-width: 55px;
    overflow: hidden;
    position: relative;
  }
  &__bell{
    min-width: 12px;
  }
  &__dropdown {
    $triangle-size: 8px;
    position: absolute;
    border-radius: 5px;
    width: 200px;
    margin-left:1.2rem;
    margin-top: 4px;
    max-height: 300px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    font-size: 12px;
    background: white;
    z-index: 10;
    &:before {
      content: '';
      border-left: #{$triangle-size} solid transparent;
      border-right: #{$triangle-size} solid transparent;
      border-bottom: #{$triangle-size} solid #ffffff;
      clear: both; 
      position: relative;
      left: 52.5%;
      transform: translateX(-50%);
      top: calc(-8px - 10px);
      width: 0; 
      height: 0; 
    }
  }

  &__count {
    position: absolute;
    transform: translateX(-50%);
    z-index: 4;
    margin-left: -13px;
    margin-top: 25px;
    padding: 0.2rem;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
  }
  &__item {
    border-bottom: 1px solid $light-grey;
    display: flex;
    flex-direction: column;
    padding-top: .5rem;
    padding-bottom: .5rem;

    &:last-child() {
      border-bottom: none;
      padding-bottom: 0;
    }
    &:first-child() {
      padding-top: 0;
    }

    &--to-view {
      background: $light-grey
    }
  }
  
}