.catalog{
  font-family: 'Quicksand-Bold';

  .new{
    -webkit-clip-path: polygon(0 0, 0 100%, 60px 0);
    clip-path: polygon(0 0, 0 100%, 60px 0);
    padding-top: 10px;
    background-color: rgb(130, 214, 67);
    position: relative;
    height: 60px;
    border-top-left-radius: 5px;
    margin-bottom: -60px; 
    text-align: left;
    margin-left: 0px;
    font-size: 20px;
    color: white;
    font-weight: 500;
  }
  
  .new-label{
    position: absolute;
    font-size: 15px;
    font-family: 'Quicksand-BOLD';
    padding-left: 0px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
  }

 
}


.hr-line {
  width: 60px;
  height: 4px;
}

.hr-up-catalog {
  background-color: #A2A2A2;
  width: 100%;
  height: 2px;
  float: left;
}

.hr-down-catalog {
  background-color: #B6B6B6;
  width: 60px;
  height: 4px;
  float: left;
}

.prod {
  padding: 0 10px;
  background: white;
  display: flex;
  flex-direction: column;
  //height: 360px;
  border-right: 1px solid rgba(0,0,0,0.17);
  border-radius: 4px;
  height: 100%;
  transition: transform 300ms ease-in-out;
  &:hover {
    transform: scale(1.04)
  }
}

.product-text{
  //height:130px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 120px;
}
.product-image{
  text-align: center;
  max-width: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  margin: auto;
  img{
    //width: 80%;
    height: inherit;
    max-width: 100%;
    //height: 185px;
    margin:auto
  }
}

.catalog-product{
  font-family: Quicksand-Light;
  padding: 20px;
  background: white;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  min-height: 300px;
  margin: 0 .1rem;
  border-radius: 2px;
  
  &__name {
    font-size: 25px;
    font-family: Quicksand-Bold;
  }
  &__hr {
    height: 2px;
    margin-top: 0;
  }

  &__points{
    font-size: 30px;
    font-family: Quicksand-Bold;
    p {
      font-family: Quicksand-Light; 
    }
  }

  &__description{
    font-size: 12px;
    font-family: Quicksand-bold;
    min-height: 50px;
    line-height: 16px;
  }
}

.total-product{
  outline: none;
  border-radius: 0;
  &--boxify {
    // margin: 5px 10px;
    border-radius: 4px;
    // margin: 10px 10px;
    // box-shadow: 4px 5px 8px -3px rgba(0,0,0,0.10),
    //   -4px -5px 8px -3px rgba(0,0,0,0.10),
    //   -4px 5px 8px -3px rgba(0,0,0,0.10),
    //   4px -5px 8px -3px rgba(0,0,0,0.10),
    //   ;
  }
}

.filters-container {
  font-size: 13px;
  word-wrap: break-word;
  display: block; 
}

.filters-catalog {
  margin-bottom: 30px;
}


@media only screen and (max-width: 765px) {
  .prod {
    box-shadow: 0 0 0 0;
    border: 0;
  }
}

.header-title {
  font-size: 17px;
  text-transform: uppercase;
}

.clear-filters {
  cursor: pointer;
  float: right;
  font-size: 14px;
}

.product-input {
  background-color: hsl(0,0%,100%);
  border-radius: 4px;
  border: 1px solid hsl(0, 0, 80%);
  display: flex;
  min-height: 38px;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px;
  margin-right: .5rem;
}
.product-button__search {
  padding: 0 20px;
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border: 1px solid hsl(0, 0, 80%);
}
