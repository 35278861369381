.g-footer {
  min-height: 30vh;
  background-color: rgb(51, 51, 51);

  &--margin-bottom-40 {
    margin-bottom: 40px;
  }
  &--contacts{
   &-link{
      color: #0984e3;
      font-size: 1.1em;
    }
  }
  &--link {
    color: inherit;
    text-decoration: none;
  }

  &--items-no-style {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__container {
    padding-bottom: 60px;
  }

  &__main {
    @extend %row;
    @extend %row--space;
  }

  &__title {
    font-size: 1.250rem;
    margin: 30px 0;
    font-weight: 400;
    font-family: 'Vodafone-Light'
  }
  
  &__item {
    font-size: 0.875rem;
    margin-bottom: 0.875rem;

    &-vodafone-contacts {
      margin-bottom: 40px;
    }
  }

  &__contract-list {
    font-size: 1rem;
    @extend %row;
    @extend %row--flex-end;
    @extend %row--center-y;
  }
  
  .ie-11 {
    writing-mode: horizontal-tb;
    -ms-writing-mode: lr-tb;
  }
}

@media(max-width:800px){ 
  .g-footer {
    
    &--center-ul {
      @include flexbox;
      @include flex-dir(column);
    }

    &__container {
      margin: 0 30px;
      padding-bottom: 70px;
    }
    
    &__main {
      @include flexbox;
      @include flex-dir(column);
    }

    &__contract-list{
      @include flexbox;
      @include flex-dir(column);
    }

    &__title {
      text-align: center;
    }
  } 
}
