.custom-inner-html {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-left: 0;
    margin-right: 0;
    word-break: break-word;
  }

  p {
      margin-top: 1em;
      margin-bottom: 1em;
  }

  h1 {
      margin-top: 0.67em;
      margin-bottom: 0.67em;
  }

  h2 {
      margin-top: 0.83em;
      margin-bottom: 0.83em;
  }

  h3 {
      margin-top: 1em;
      margin-bottom: 1em;
  }

  h4 {
      margin-top: 1.33em;
      margin-bottom: 1.33em;
  }

  h5 {
      margin-top: 1.67em;
      margin-bottom: 1.67em;
  }

  h6 {
      margin-top: 2.33em;
      margin-bottom: 2.33em;
  }
  iframe{
    width: 100%;
  }
}

@media (max-width: 600px) {
  .custom-inner-html {
    *{
      font-size: .9rem !important;
      word-wrap: break-word;
    }
  }
}