.custom-select{
  &__placeholder{
    font-size: 16px;
  }
  &__indicator-separator{
    background-color: transparent !important;
  }
  &__menu{
    margin-top: 0px !important;
    border-radius:0 !important;
    z-index: 10 !important;
    width: calc(100% - 1rem) !important;
    bottom: 0;
    position: absolute;
    height: fit-content;
  }
  &__control {
    position: relative;
  }

  &__control--menu-is-open{
    border-radius: 0 !important;
  }
  &__option{
    text-align:left;
  }
  &__option:hover, &__option:active{
    color: white;
    background-color: none;
    background-color: #5bc0de;
  }
  &__option--is-focused{
    background-color: #5bc0de;
  }

  &__list {
    position: absolute;
    width: 100%;
    background: #ffffff;
  }

  &__menu-list {
    background: rgb(255,255,255);

  }
}
