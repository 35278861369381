.logo{
  max-height: 150px;
}
.navbar-header-links{
  background: #eaeaef;
  min-height: 45px;
  line-height: 45px;

  &-text{
    font-size: 12px;
    color: #333;
    padding: 0 7.5px;
    border-right: 1px solid;
    text-decoration: none;
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
  }
  &-text:hover{
    text-decoration: underline;
  }
  &-text:last-child{
    border-right: none;
    padding-right: 0;
  }
}

.user-bar{
  font-family: 'Quicksand-Bold';


}

.content-user-bar{
  margin: 10px;
  min-height: 36px;
}

.content-user-bar-plus{
  line-height: 56px;
  font-size: 35px;
}

.cart-container {
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0;

  .cart-image {
    position: relative;
    top: 5px;
    height: 40px;
    margin-right: 10px;
  }
  
  .cart-link {
    text-decoration: none;
    padding: 0 10px;
  }
  
  .cart-items {
    color: white;
    font-size: 40px;
  
  }

}

.items{
  float: left;
  left: -10px;
  font-size: 12px;
  top: 0px;
  padding: 5px;
  z-index: 1000;
  background-color: #E7802C;
  border-radius: 50%;
  color: #FFFFFF;
  font-weight: bold;
}

.profile-image{
  height: 65px;
  width: 65px;
  border: 3px solid #00CAC4;
  display: flex;
  border-radius: 50%;
  margin-top: -4px;
  margin-bottom: -5px;
  border-width: 3px;
}

.col-blue{
  background-color: #002569;
  color: white;
  text-align: center;
}

.col-orange{
  background-color: #E7802C;
  color: white;
}



#navbar {
  font-family: Quicksand-light;
  font-weight: 900;
  font-size: 18px;
  
  .nav-item {
    color: #FFF;
  }
  
  .navWide {
    display: flex;
    justify-content: space-between;
    
    .wideDiv {
      display:flex;
   

      .selected{
        text-decoration: none;
        display: inline-block;
        padding: 19px;
        background: linear-gradient(to top,  #33D9CC 0%,#33D9CC 10%,#04369B 10%,#04369B 100%);
        color: white;
      }
      .selected:hover{
        background: linear-gradient(to top,  rgb(43, 187, 175) 0%,rgb(43, 187, 175) 10%,rgb(4, 45, 128) 10%,rgb(4, 45, 128) 100%);
        color: white;
      }
    }
  }
  
  .navNarrow {
    
    i {
      padding: 15px 10px;
      float: left;
      cursor: pointer;
      color: #FFF;
    }
    
    .narrowLinks {
      display: none;
      
      .nav-item {
        text-decoration: none;
        display: block;
        float: left;
        clear: left;
        padding: 14px 100% 14px 2%;
      }

      .selected{
        text-decoration: none;
        display: inline-block;
        padding: 14px 90% 14px 2%;
        background: linear-gradient(to top,  #33D9CC 0%,#33D9CC 10%,#04369B 10%,#04369B 100%);
      }

      .selected:hover{
        color: white !important;
      }
    }
  }
}

.nav-item {
  height: 100%;
  text-decoration: none;
  padding: 19px;
}

.dropbtn {
  height: 100%;
  // color: white;
  font-size: 16.6px;
  padding: 19px;
  text-align: left;
  &:focus {
    outline:0;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 5;
  overflow: auto;
  word-break: break-word;
  hyphens: auto;
  font-size: 16px;
  min-width: 160px;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 0;
  .dropdownIcon {
    margin-left: 5px;
    position: relative;
  }

  &:hover {
    .dropdown-content {
      display: block
    }
  }
}



.dropdown-content:hover {
  display: block;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
  margin: auto;
  width: 100%;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  
}

.burger {
  display: none;
  position: fixed;
  top: 5px;
  left: 5px;
  width: fit-content;
  z-index: 50;
  padding: 5px;;
  border-radius: 2px;

  span {
    display: flex;
    height: 3px;
    width: 40px;
    margin: 3px 0px;
    background: white;
  }

}
.nav-mobile-close {
  display: none;
  flex-direction: column;
  position: fixed;
  right: 18px;
  top: 15px;
  z-index: 200;
}

.show {display: block;}

@media(max-width: 800px){
  
  .dropbtn {width: 100%;}
  #navbar .navWide, #navbar .navWide .wideDiv {display: flow-root !important;}
  nav{
    opacity: 1;
	transition:all .4s;

    .cont {
      margin: 0;
    }
    .navWide {
      background: inherit;
      position: fixed; 
      min-height: 100%;
      z-index: 100;
      overflow-y: auto;
      top: 0;
      left:0;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      .wideDiv {
        flex-direction: column;
        justify-content: flex-start;
        min-height: fit-content;
		display: flow-root;
      }
      .nav-item {
        display: flex;
        width: 100%;
        height: initial;
      }

      .dropdown {
        width: 100%;
		transition: 0.3s ease;
		
			.dropbtn {
				transition: 0.3s ease;
				height: 100%;
				  // color: white;
				  font-size: 16.6px;
				  padding: 14px;
				  text-align: left;
				  height:auto;
				  transition:all .4s;
				  &:focus {
						transition:all .4s;
						outline:0;
				  }
			}

        .dropdown-content {
			transition: 0.3s ease;
			display: none;
			/*display: flex;*/
			flex-direction: column;
			z-index: 0;
			box-shadow: none;
			position: relative;
			a {
				box-shadow: none;
			}
        }
		
		  &:hover {
			.dropdown-content {
				transition: 0.3s ease;
				display: block;
			}
			.fromDropdown, .fKmOcp{
				padding-left:30px;
			}
		  }		  
		
		
      }
    }
  }
  .nav-mobile-close {
    display: flex;
  }
  .burger {
    display: flex;
    flex-direction: column;
  }
  .fade {
    opacity: 0;
    display: none;
    .nav-mobile-close {
      display: initial;
    }
  }
  
}

@media (min-width: 1045px) {
  nav {
    .navNarrow {
      display: none;
    }
  }

}

@media (max-width: 420px) {
  .margin-logout{
    margin-bottom: 60px !important;
  }
}

