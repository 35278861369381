$gray: #8E959B;
$g-header-size: 11px;
$g-row-height: 60px;

.g-cart {
  @extend %vodafone-bold;

  &__title {
    font-size: $title-size;
  }
  &__cell{
    padding-top: 2em;
    padding-bottom: 2em;
  }

  &__table{
    border-spacing: 5px;
  }

  &__header{
    @extend %dark-grey;
    font-size: 11px;
  }

  &__data{
    color: $gray;
    border-bottom: 1px solid rgba($gray, 0.1);
  }

  &__navbar{
    font-weight: bold;
    justify-content:center;
    color: $gray;
    border-bottom: 1px solid rgba($gray, 0.1);
    &-active{
      @extend %dark-grey;
      &:visited{
        @extend %dark-grey;
      }
    }
  }

  &__number-cell-4 {
    width: 4%;
  }

  &__number-cell-60 {
    width: 60%;
  }

  &__number-cell-10 {
    width: 10%;
  }

  &__address {
    font-size: 16px;
    @extend %dark-grey;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 280px;
    &--small {
      font-size: $small-title-size;
    }

    &--min-height {
      min-height: 280px;
    }
    &--selected {
      border-radius: 3px;
      background: #FAFAFA;
    }
  }

  &__select-address-container{
    display: flex;
    justify-content: space-between;
  }
  &__empty-state {
    height: 32px;
    width: 32px;
  }
}
