.gl-posts{
  &-sales{
    &__container{
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      flex-direction: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin: 0;
      border-width: 2px;
      width: 160px;
      height: 290px;
      padding: 0;
      transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
      min-height: 0;
    }
    &__title{
      font-weight: 500;
      margin: 5px 0;
      -webkit-justify-content: center;
      justify-content: center;
      width: 100%;
      font-size: 14px;
      text-align: center;
    }
    &__image{
      width: calc(130px - 2px * 2);
      height: auto;
    }
    &__action-container{
      margin: 0;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      width: 100%;
      padding-top: 22.5px;
      padding-bottom: 5px;
    }
    &__points{
      &-container{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        align-items: center;
      }
      &-value{
        font-weight: bold;
        font-size: 28px;
      }
      &-label{
        font-size: 15px;
      }
    }
  }
}