.g-analytics {

  &__employee {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__select {
    width: 290px;
  }

}