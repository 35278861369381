.invoice {
  &__table-header {
    height: 50px;
    font-size: 14px;
  }

  &__table-cell {
    &--small {
      width: 8%;
    }
    &--medium {
      width: 10%;
    }
    &--big {
      width: 12%;
    }
  }
  &__table-row {
    height: 50px;;
  }
}
.table-text {
  font-size: 13px;
}
.invoice-error{
  color: #DA4D15;
}

@media (max-width: 450px) {
  .font-10{
    font-size:11px;
  }
}

.modal__content {
  min-height: 690px !important;
}
