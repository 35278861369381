.gl-news {
  &__remove {
    @extend %row;
    @extend %row--center;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    padding: 5px;
    color: white;
    border-radius: 3px;
    
  }
  &__empty-icon{
    height: 140px;
  }
}
