.image-container {
  width: 100%;
  height: 200px;
  border: solid;
}

.textbutton {
    color: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.50);
}


.page-content, .page-text-container   {
  width: 100%;
}

.page-text-container {
    word-break: break-word;
    hyphens: auto;
}
