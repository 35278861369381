$gl-banner-height: 500px;
.gl-home-banner {
  &__container, &__image {
    width: 100%;
    @include resized-elements;
  }

  &__container {
    @include gradient {
      z-index: 0;
    };
    @extend %gl-p-container;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    background: #999;
  }

  &__image {
    position: absolute;
    top:0;
    left:0;
  }

  &__margin {
    margin-top: 140px;
    margin-bottom: 140px;
  }

  &__title {
    width: 600px;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 1px;
    line-height: 40px;
  }

  &__link {
    padding: 10px 20px;
    background: #ffffff;
    height: fit-content;
    font-weight: lighter;
  }

  &__dots{
    padding: 0;
    li{
      font-size: 5.8rem;
      width: auto;
      height: auto;
      margin: 0 -1.5px;
    }
  }

  &__slider{
    .slick-list{
      width: 100%;
      pointer-events: none;
      .slick-active{
        cursor: unset;
      }
    }
    .slick-dots{
      width: 100%;
      bottom: -5px;
    }
  }
}