// contains the layout/container styles

$percentage: 10%;
$mobile-percentage: 1%;

.p-container, %p-container {
  padding-right: $percentage;
  padding-left: $percentage;
}

.m-container {
  margin-right: $percentage;
  margin-left: $percentage;
  // max-width: 1000px;
}

.g-content-container {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  height: fit-content;
  padding-top: 30px;
  padding-bottom: 30px;
}

.above-content {
  z-index: 1;

  &-2 {
    z-index: 2;
  }
}

.centered-bg, %centered-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &--contain {
    background-size: contain;

  }
}

.g-home-container {
  min-height: 80vh;
  &__content{
    min-height: fit-content;
  }
}

.ranking-loader-container {
  // width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ranking-container {
  width: 300px;
  max-height: 80vh;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.default-bg {
  background: #ffffff;
}

.g-content-shadow {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
}

.g-content-shadow--main {
  box-shadow: 0 2px 20px 0 rgba(223, 128, 4, 0.808);
}

.g-round {
  border-radius: 1px;
}

.outline-reset {
  outline: 0;
  &:active, &:focus {
    outline: 0;
  }
}

.g-pagination {

  display:flex;
  padding:0;
  padding-left: .5rem;
  flex-wrap: wrap;
  align-items: center;
  &__page {
    padding: 5px;
    margin: 0 10px;
    border-radius: 2px;
  }
}

.g-shadow {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
}
.g-active-page {
  &>a, &>a:focus{
    outline: none;
  }
}

.g-home-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.g-home-number {
  min-width: 25px;
  text-align: right;
}
.empty-state-svg {
  width: 100px;
  height: 100px;
  fill: rgb(208, 208, 208);
}



.g-gradient {
  @include gradient {
    border-radius: 10px;
    z-index: $behind-content; 
  }
}
.max-50px-text{

  & > * {
    max-height: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.g-line {
  display: block;
  height: 1px;
  width: 100%;
}

.g-no-store {
  margin-top: 50px;
  margin-bottom: 50px;

  &__image {
    font-size: 200px;
  }
}

//mobile

@media(max-width: 1150px){
  .p-container, %p-container {
    padding-right: 5%;
    padding-left: 5%;
  }

  .m-container {
    margin-right: 5%;
    margin-left: 5%;
  }

  .g-home-container {
    min-height: 80vh;
  }
}

@media(max-width:500px){
  .p-container, %p-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .content{
    z-index: $above-content;       
  }
}

@media(min-width: 400px) and (max-width: 900px) {
  .mb\:{
    &p-container--cancel {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media(max-width:400px){
  .p-container, %p-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .content{
    z-index: $above-content;       
  }
}

@media(max-width: 900px){
  .g-home-container {
    height: fit-content;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .ranking-container {
    width: 100%;
    min-height: 80vh;
    max-height: initial;
    height: 0;
  }

  .left-box {
    margin-right: 0;
    margin-bottom: 2.5rem;
  }
}

@media(max-width: 500px){
  .home-info {
    justify-content: center;

    &__title {
      text-align: center;
      margin: 2px;
    }

    &__description {
      display: none;
    }
  }
}

@media(min-width: 1650px) {
  %p-container, .p-container {
    padding-right: 18%;
    padding-left: 18%;
  }
  %m-container, .m-container {
    margin-right: 18%;
    margin-left: 18%;
  }
}

@media (min-width: 576px) {
  .sm\:flex_important {
    display: flex !important;
  }

}

@media (min-width: 768px) {
  .md\:flex_important {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .lg\:flex_important {
    display: flex !important;
  }
}

@media (min-width: 1200px) {
  .xl\:flex_important {
    display: flex !important;
  }
}
