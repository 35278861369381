.tooltip-container {
  position:  absolute;
  padding: 8px;
  background-color: #f0f0f0;
  border: solid 1px #707070;
  z-index: 1000;
}

.tooltip-marker {
  display: none;
  float: left;
  height: 16px; 
  width: 16px;
  margin-right: 5px;
}

.tooltip-text {
  float: left;
  color: #000;
}
