// contains the layout/container styles

$gl-layout-percentage: 10%;

.gl-layout{
  min-height: 72vh !important;
}
.gl-p-container, %gl-p-container {
  padding-right: $gl-layout-percentage;
  padding-left: $gl-layout-percentage;
}

.gl-above-content {
  z-index: 1;

  &-2 {
    z-index: 2;
  }
}
.gl-full-bg, %gl-full-bg{
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.gl-centered-bg, %gl-centered-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &--contain {
    background-size: contain;
  }
}

.gl-home-container {
  min-height: 80vh;
  &__content{
    min-height: fit-content;
  }
}

.gl-ranking-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gl-ranking-container {
  width: 300px;
  max-height: 80vh;
}

.gl-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gl-default-bg {
  background: #ffffff;
}

.gl-content-shadow {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
}

.gl-content-shadow--main {
  box-shadow: 0 2px 20px 0 rgba(223, 128, 4, 0.808);
}

.gl-round {
  border-radius: 1px;
}

.gl-outline-reset {
  outline: 0;
  &:active, &:focus {
    outline: 0;
  }
}

.gl-pagination {

  display:flex;
  padding:0;
  padding-left: .5rem;
  flex-wrap: wrap;
  align-items: center;
  &__page {
    padding: 5px;
    margin: 0 10px;
    border-radius: 2px;
  }
}

.gl-shadow {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
}
.gl-active-page {
  &>a, &>a:focus{
    outline: none;
  }
}

.gl-home-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.gl-home-number {
  min-width: 25px;
  text-align: right;
}
.empty-state-svg {
  width: 100px;
  height: 100px;
  fill: rgb(208, 208, 208);
}



.gl-gradient {
  @include gradient {
    border-radius: 10px;
    z-index: $gl-behind-content; 
  }
}
.gl-max-50px-text{

  & > * {
    max-height: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.gl-line {
  display: block;
  height: 1px;
  width: 100%;
}

.gl-no-store {
  margin-top: 50px;
  margin-bottom: 50px;

  &__image {
    font-size: 200px;
  }
}

//mobile

@media(max-width: 1150px){
  .gl-p-container, %gl-p-container {
    padding-right: 5%;
    padding-left: 5%;
  }

  .gl-home-container {
    min-height: 80vh;
  }
}

@media(max-width:500px){
  .gl-p-container, %gl-p-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .gl-content{
    z-index: $gl-above-content;       
  }
}

@media(min-width: 400px) and (max-width: 900px) {
  .mb\:{
    &gl-p-container--cancel {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media(max-width:400px){
  .gl-p-container, %gl-p-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .gl-content{
    z-index: $gl-above-content;       
  }
}

@media(max-width: 900px){
  .gl-home-container {
    height: fit-content;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .gl-ranking-container {
    width: 100%;
    min-height: 80vh;
    max-height: initial;
    height: 0;
  }

  .gl-left-box {
    margin-right: 0;
    margin-bottom: 2.5rem;
  }
}

@media(max-width: 500px){
  .gl-home-info {
    justify-content: center;

    &__title {
      text-align: center;
      margin: 2px;
    }

    &__description {
      display: none;
    }
  }
}

@media(min-width: 1650px) {
  %gl-p-container, .gl-p-container {
    padding-right: 18%;
    padding-left: 18%;
  }
}