.input{
  height: 30px;
  width: 50%;
}
.login-user{
  border: 1px solid $gray;
}
.login-password{
  border: 1px solid $gray;
  border-top: none;
}
.checkbox-label{
  color: #002569
}

.input-round {
  border-radius: 5px;
}

.consent-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  padding-left: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.consent-checkbox-input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
} 

.consent-checkbox-label {
  position: absolute;
  border: 1px solid #a4b0be;
  top: 1.5px;
  left: 0;
  height: 25px;
  width: 25px;
}

.consent-checkbox-input:checked ~ .consent-checkbox-label {
  background-color: #0652DD;
  border: 1px solid transparent;
}
.consent-checkbox-input:disabled ~ .consent-checkbox-label {
  cursor: not-allowed;
}
.consent-checkbox-input:checked:disabled ~ .consent-checkbox-label {
  background-color: #a4b0be;
  border: 1px solid transparent;
}

.consent-checkbox-label:after {
  content: "";
  position: absolute;
  display: none;
}

.consent-checkbox-input:checked ~ .consent-checkbox-label:after {
  display: block;
}

.consent-checkbox-container .consent-checkbox-label:after {
  left: 7px;
  bottom: 2px;
  width: 10px;
  height: 22px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.consent-text-container{
  line-height: 25px;
}
.consent-text{
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
  word-break: break-word;
}
.consent-seemore{
  cursor: pointer;
  color: #0984e3;
  font-size: 14px;
}
.consent-exit{
  font-size: 25px;
  border-radius: 50%;
  background: white;
  color: #979797;
  position: absolute;
  right: 10px;
  top: 10px;
}
.consent-modal-description{
  max-height: 80vh;
  overflow-y: scroll
}
.consent-modal-description::-webkit-scrollbar{
  display:none;
}
