$large-desktop-width: 1920px;
$max-laptop-width: 1440px;
$max-tablet-width: 800px;
$max-mobile-width: 500px;

@mixin default-resized-elements{
  min-height: 600px;
  max-height: 600px;
  @media (max-width: #{$max-laptop-width}) {
    min-height: 500px;
    max-height: 500px;
	background-position: -58px 0px !important;
  }
  @media (max-width: #{$max-tablet-width}) {
    min-height: 425px;
    max-height: 425px;
  }
  @media (max-width: #{$max-mobile-width}) {
    min-height: 350px;
    max-height: 350px;
  }
}

.font-quicksand{
  font-family: 'Quicksand-Bold';
}
.font-quicksand-regular{
  font-family: 'Quicksand-Regular';
}
.font-quicksand-light {
  font-family: 'Quicksand-Light';
  font-weight: 900;
}
.image-background {
  left: 0;
  top: 0;
  min-height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(90deg, #E38200 10%, 30%, #00369B 60%);
  background-size: cover;
  background-repeat: no-repeat;

  &--no-overlay {
    position: relative;
  }
}
.logo-container{
  img{
    width:50%;
    height: 200px;
  }
}
.w46{
  width: 46%;
}
.w-10{
  width: 10%;
}
.w-15{
  width: 15%;
}
.w-5{
  width: 5%;
}

.default-container{
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  background:white;
}
.default-container-login{
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  background:white;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: auto;
}

.default-container-privacy-policy {
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  background:white;
  border-radius: 15px;
  margin: 50px;
  padding: 50px;

  > h1 {
    padding: 10px 0;
  }
  > h2 {
    padding-bottom: 10px;
  }

  > p, ul {
    margin: 12px 0;
  }

}

.template{
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
  background:white;
  padding:20px;
  
  &-container{
    border-radius: 4px;
    background: rgb(234, 234, 239);
    padding: 20px 15px;
    margin: 25px 0;
    &--light {
      background: #f5f5f5;
    }
  }
  &-show{
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.17);
  }
  &-title{
    font-weight:normal;
    color: #002569;
  }
  &-block{
    padding: 20px 0;
  }
  &-text{
    font-weight:300;
    color: rgb(234, 234, 239);
    font-size: 22px;
  }
  &-blue{
    min-height: 50px;
    font-size: 24px;
    color: #002569;
    border-bottom: 1px solid #002569;
    padding: 0 10px;
  }
  &-errors{
    color: #DA4D15;
    font-size: 16px;
    padding: 10px 10px;
  }
  &-hint{
    color: #40739e;
    font-size: 14px;
    padding: 0 5px;
  }
  &-pass{
    line-height: 50px;
    font-size: 24px;
    color: #002569;
    border-bottom: 1px solid #002569;
    padding-left: 10px;
    padding-right: 10px;
  }
  &-link-blue{
    line-height: 40px;
    font-size: 18px;
    color: #002569;
    text-decoration: underline;
  }
  &-warning{
    
    line-height: 18px;
    letter-spacing: 0.2px;
    background-color: #DA4D15;
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    color:white;
    padding: 5px;
  }
  &-info{
    letter-spacing: 2.5px;
    font-size: 19px;
    color: white;
    padding: 12px 0 12px 10px;
    &--reduced-spacing {
      letter-spacing: 1.5px;
    }
  }
  &-passwordinfo{
    background: rgb(234, 234, 239);
    margin-top: 10px;
    min-height: 50px;
    border-radius: 0 0 4px 4px;
    padding: 0 20px 30px 20px;
  }
  &-password-title{
    
    padding: 10px 0 20px 0;
    color: #706F6F;
    font-weight: bold;
    font-size: 14px;
  }
  &-password-requirements{
    font-size: 12px;
    padding-left: 15px;
    color: #706F6F;
  }
  &-text-blue{
    color: #002569;
    font-size: 20px;
  }
  &-text-newsletter{
    color: #002569;
    font-size:16px;
  }
  &-address{
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
    border-radius: 6px;
    &-title{
      color: #002569;
      font-size: 20px;
      line-height: 80px;
      &-text{
        display:inline-block;
        vertical-align: middle;
        line-height:normal;
        word-wrap: anywhere;
      }
    }
    &-blue{
      color: #002569;
      font-size: 20px;
      padding: 2px 0;
      
    }
    &-gray{
      color: $gray;
      font-size: 18px;
      padding: 2px 0;
    }
    &-trash{
      color: $gray;
      font-size: 18px;
      line-height:80px;
      cursor: pointer;
    }
  }
}
.hr-down-left{
  background-color: #E7802C;
  width: 45%;
  height: 3px;
  float:left;

}
.ranking-vertical{
  width: 7px;
  background: white;
  border-radius: 2px;
  box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
}
.background-blue{
  background: linear-gradient(90deg, #00369B 10%, 30%, #00369B 60%);
}
.ranking-position{
  position: absolute;
  bottom: 15px;
  left: -20px;
  border-radius: 50%;
  border: 5px solid #1cbbb4;
  height: 45px;
  width: 45px;
  color: white;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.17);
  background: linear-gradient(115deg, #00369b 0%,30%, #1cbbb4 70%);
  padding: 0px 5px;
  font-size: 30px;
  &-span{
    font-family: sans-serif
  }
}
.ranking-pointer{
  height: 20px;
  width: 7px;
}

.ranking-scale {
  margin-top: -30px;
  
}
.slide-container{
  position: relative;
  margin-left: 0 !important;
  margin-right: 0 !important;
  background: rgb(234, 234, 239)
}
.sliding{
  position: relative;
  @include default-resized-elements;
  width: 100%;
  z-index:3;
  
  img{
    width:inherit;
    height:inherit;
  }
  
  .banner-content {
    position: absolute;
    top: 20%;
    right: 0;
    text-align: left;
    color: white;
    padding: 20px;
    &:before {
      background: #003e51;
      border-radius: 25px;
      opacity: 0.4;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -1;
    }
    .banner-title {
      z-index: 2;
      font-size: 47px;
      font-family: 'Quicksand-Bold';
      font-weight: 800;
      &.sub-title {
        font-size: 38px;        
      }
    }
  }
  
  .button-wrapper {
    position: absolute;
    bottom: 10%;
    right: 0;
    .banner-button {
      padding: 20px;
      border-radius: 25px;
      font-size: 30px;
    }
  }
}
.slick-dots li button::before{
  font-size: 18px;
  line-height: 18px;
  position: absolute;
  top: 1.25px;
  left: 0.25px;
  width: 18px;
  height: 18px;
  content: '•';
  text-align: center;
  opacity: .25;
}
.slick-dots li.slick-active button::before{
  opacity: 1;
  color:inherit;
}

.slick-active {
  z-index: 1;
}

.slick-dots li button:hover::before, .slick-dots li button:focus::before{
  opacity: 1;
  color:inherit;
}
.width-available {
  width: 100%;
}

.soft-round {
  border-radius: 4px;
}

.anchor-reset {
  color:inherit;
  &:visited {
    color: inherit;
  }
}

.feed {
  &-thumb {
    height: 100%;
    width: 100%;
  }

  &-container{
    min-height: 640px;
    max-height: 640px;
    overflow: auto;
  }
}
.layout-above {
  position: absolute;
  top: 0;
  left: 0;
  height: fit-content;
  min-height: 100%;
  width: 100%;
}

@media(max-width:400px){
  .xs\:{
    &:w-full {
      width: 100%;
    }
    &no-display {
      display: none;
    }
    &mb-10 {
      margin-bottom: 2.5rem;
    }
    &flex-turn {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      &--j-center {
        justify-content: center;
      }
      &--a-center {
        align-items: center;
      }
      &--reverse {
        flex-direction: column-reverse
      }
    } 
  }
}

@media(max-width:800px){
  .sm\:{
    &w-full {
      width: 100%;
    }
    &no-display {
      display: none;
    }
    &mb-4 {
      margin-bottom: 1rem;
    }
    &flex-turn {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      &--j-center {
        justify-content: center;
      }
      &--a-center {
        align-items: center;
      }
      &--reverse {
        flex-direction: column-reverse
      }
    }
  
  }

  .template {
    padding: 10px;
  }
}

@media (max-width: #{$max-mobile-width}) {
  .sliding{
    .banner-content {
      top: 10%;
      &:before {
        border-radius: 12.5px;
        opacity: 0.4;
        content: '';
        z-index: -1;
      }
      h1{
        font-size: 20px;
      }
      h2{
        font-size: 18px;
      }
    }
      
    .button-wrapper {
      position: absolute;
      bottom: 12.5%;
      right: 0;
      .banner-button {
        padding: 12.5px;
        border-radius: 17.5px;
        font-size: 16px;
      }
    }
  }
}

.hyphens {
  hyphens: auto;
}

.break {
  word-break: break-word;
}

.mx-bs {
  margin-right: .5rem;
  margin-left: .5rem;
}
.-mx-bs {
  margin-right: -.5rem;
  margin-left: -.5rem;
}

.mr-bs {
  margin-right: .5rem;
}

.ml-bs {
  margin-left: .5rem;
}
.gray{
  color: $gray;
}
.text-break {
  word-wrap: anywhere;
}