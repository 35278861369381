.home-banner {
  &__container, &__image {
    width: 100%;
    @include g-resized-elements;
  }

  &__container {
    @include gradient {
      z-index: 0;
    };
    @extend %p-container;
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    background: #999;
  }

  &__image {
    position: absolute;
    top:0;
    left:0;
  }

  &__margin {
    margin-top: 140px;
    margin-bottom: 140px;
  }

  &__title {
    width: 600px;
    font-size: 30px;
    color: #ffffff;
    letter-spacing: 1px;
    line-height: 40px;
  }

  &__link {
    padding: 10px 20px;
    background: #ffffff;
    height: fit-content;
    font-weight: lighter;
  }
}
