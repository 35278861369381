$gl-user-text-highlight: 18px;
$gl-image-height: 140px;
.gl-user {
  &-name {
    font-weight: bold;
    font-size: $gl-user-text-highlight;
    width: fit-content;
    margin-bottom: 10px;
  }

  &__link {
    height: fit-content;
    align-self: center;
    max-width: $gl-image-height;
    min-width: $gl-image-height;
    max-height: $gl-image-height;
    min-height: $gl-image-height;
  }

  &-container {
    margin-top: 65px;
    display: flex;
    height: 105px;
    justify-content: flex-end;
    align-content: center;
    position: relative;
    text-align: left;
    width: 100%;
    &-bubbles{
      min-height: 150px;
    }
  }

  &-content {
    margin-top: 30px;
    min-height: fit-content;
    height: min-content;
    position: relative;
    z-index: $gl-above-content;

    &--home {
      &:after {
        position: absolute;
        content: '';
        background: rgba(75, 75, 75, 0.75);
        width: 100%;
        @include resized-elements;
        top: 75px;
        left: 0;
        z-index: $gl-behind-content;
      }

    }
  }

  &__info {
    display: flex;
    max-height: $gl-image-height;
  }

  &-data {
    margin-left: 10px;
    height: calc(#{$gl-image-height} / 2);
    display: flex;
    flex-direction: column;
  }

  &-avatar {
    align-self: center;
    height: $gl-image-height;
    width: auto;
    border-radius: 50%;
    border-color: white;
    border-width: 10px;
    z-index: $gl-above-content;
    background: white;

    &--plain {
      z-index: 0;
    }

    &--small {
      height: 90px;
      width: 90px;
    }
  }

  &-points {
    &__value {
      font-weight: bold;
      margin-right: 5px;
    }
  }
}

@media ( max-width: 576px ){
  .gl-user {
    &-avatar {
      align-self: center;
      height: $gl-image-height;
      width: 90px;
      height:90px;
      z-index: $gl-above-content;
      border:none;
      margin-top: 10px;
    }
    &-content {
      margin-top: 0;
      &--home {
        &:after{
          display: none;
        }
      }
    }
    &-data{
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
 
}
