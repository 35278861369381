$triangle: 8px;
$padding-notifications : 20px;
$border-radius-notfications : 5px;

.notification__dropdown__scrolling{
  overflow-y: scroll;
  border-radius: $border-radius-notfications;
}
.notification__dropdown__scrolling::-webkit-scrollbar{
  display:none;
}
.g-notification {
  &__container {
    position: relative;
  }

  &__count {
    position: absolute;
    right: 0%;
    bottom: 52.5%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0;
  }

  &__dropdown {
    $triangle-size: 8px;
    position: absolute;
    width: 280px;
    max-height: 300px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: $border-radius-notfications;
    padding: 2px;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    font-size: $small-title-size;
    background: white;

    &:before {
      content: '';
      border-left: #{$triangle-size} solid transparent;
      border-right: #{$triangle-size} solid transparent;
      border-bottom: #{$triangle-size} solid #ffffff;
      // box-shadow: 0 2px 20px 0 rgba(0,0,0,0.17);
      clear: both;
      
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -8px;
      width: 0; 
      height: 0; 
    }
  }

  &__item {
    border-bottom: 1px solid $light-grey;
    display: flex;
    flex-direction: column;
    padding-top: $padding-notifications;
    padding-bottom: $padding-notifications;

    &--to-view {
      $circle-width-height : 6px;
      content: '';
      min-width: $circle-width-height;
      min-height: $circle-width-height;
      width: $circle-width-height;
      height: $circle-width-height;
      border-radius: 50%;
      margin-right: 10px;
      margin-top: 5px;
    }

    &-container {
      padding-right: $padding-notifications;
      padding-left: $padding-notifications;

      &:last-child() {
        border-bottom-right-radius: $border-radius-notfications;
        border-bottom-left-radius: $border-radius-notfications;
        padding-bottom: 0;
      }
      &:first-child() {
        border-top-left-radius: $border-radius-notfications;
        border-top-right-radius: $border-radius-notfications;
        padding-top: 0;
      }
    }
  }

  &__announcement-icon {
    width: 18px;
    height: 18px;
  }
}
@media(max-width: 576px ){
  .g-notification {
    &__dropdown {
      left: 150%;
      &:before{
        left: 33%;
      }
    }
    
  }
}